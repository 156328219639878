import React from "react";

import { Button } from "../ui/button.tsx";

import useStyles from "../../Hooks/useStyles.ts";

import { useFormEditorContext } from "../../Contexts/FormEditorContext.jsx";

type FormButtonProps = React.HTMLAttributes<HTMLInputElement> & {
  children?: React.ReactNode;
};

function FormButton({ children, ...props }: FormButtonProps) {
  const { getStyles } = useStyles();
  const { theme, goToNext, fields, selectedField } = useFormEditorContext();

  const isLastField = fields?.[fields?.length - 1]?.id === selectedField?.id;

  const Text = isLastField ? "Submit" : children;

  return (
    <Button
      style={{
        backgroundColor: theme?.button_color,
        color: theme?.button_text_color,
        borderRadius: theme?.rounded_corners,
        fontFamily: `${theme?.font_family?.family}, ${theme?.font_family?.category}`,
        fontWeight: theme?.is_bold === "true" ? "bold" : "normal",
        width: getStyles("button", theme?.button_size).width,
        height: getStyles("button", theme?.button_size).height,
      }}
      onClick={goToNext}
      className={props.className}
    >
      {Text}
    </Button>
  );
}

export default FormButton;
