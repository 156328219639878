type TextStyle = {
  fontSize: string;
  fontWeight: string;
};

type ButtonStyle = {
  width: string;
  height: string;
};

type Element = "h1" | "h2" | "h3" | "h4" | "button";

type Size = "small" | "medium" | "large";

type Device = "desktop" | "mobile";

const Styles = {
  desktop: {
    h1: {
      small: {
        fontSize: "30px",
        fontWeight: "700",
      },
      medium: {
        fontSize: "40px",
        fontWeight: "700",
      },
      large: {
        fontSize: "50px",
        fontWeight: "700",
      },
    },
    h2: {
      small: {
        fontSize: "20px",
        fontWeight: "700",
      },
      medium: {
        fontSize: "24px",
        fontWeight: "700",
      },
      large: {
        fontSize: "30px",
        fontWeight: "700",
      },
    },
    h3: {
      small: {
        fontSize: "16px",
        fontWeight: "700",
      },
      medium: {
        fontSize: "20px",
        fontWeight: "700",
      },
      large: {
        fontSize: "28px",
        fontWeight: "700",
      },
    },
    h4: {
      small: {
        fontSize: "16px",
        fontWeight: "400",
      },
      medium: {
        fontSize: "20px",
        fontWeight: "400",
      },
      large: {
        fontSize: "28px",
        fontWeight: "400",
      },
    },
    button: {
      small: {
        width: "59px",
        height: "36px",
      },
      medium: {
        width: "69px",
        height: "40px",
      },
      large: {
        width: "79px",
        height: "44px",
      },
    },
  },
  mobile: {
    h1: {
      small: {
        fontSize: "28px",
        fontWeight: "700",
      },
      medium: {
        fontSize: "36px",
        fontWeight: "700",
      },
      large: {
        fontSize: "42px",
        fontWeight: "700",
      },
    },
    h2: {
      small: {
        fontSize: "16px",
        fontWeight: "700",
      },
      medium: {
        fontSize: "20px",
        fontWeight: "700",
      },
      large: {
        fontSize: "24px",
        fontWeight: "700",
      },
    },
    h3: {
      small: {
        fontSize: "12px",
        fontWeight: "700",
      },
      medium: {
        fontSize: "16px",
        fontWeight: "700",
      },
      large: {
        fontSize: "20px",
        fontWeight: "700",
      },
    },
    h4: {
      small: {
        fontSize: "12px",
        fontWeight: "400",
      },
      medium: {
        fontSize: "16px",
        fontWeight: "400",
      },
      large: {
        fontSize: "28px",
        fontWeight: "400",
      },
    },
    button: {
      small: {
        width: "59px",
        height: "36px",
      },
      medium: {
        width: "69px",
        height: "40px",
      },
      large: {
        width: "79px",
        height: "44px",
      },
    },
  },
};

function useStyles() {
  const getStyles = (element: Element, size: Size, device?: Device): any => {
    return Styles[device ?? "desktop"][element][size];
  };

  return { getStyles };
}

export default useStyles;
