import React from "react";
import { RightOutlined } from "@ant-design/icons";

const FeatureCard = () => {
  return (
    <div className="relative flex flex-col max-w-md p-6 border shadow-sm rounded-2xl bg-gradient-to-r from-white to-gray-100 ">
      <div className="flex items-center gap-1  justify-center bg-[#D4FD04] w-[115px]  rounded-2xl">
        <span className="block text-xs font-semibold text-[#191B33]">
          Coming Soon
        </span>
        <div>{"🎉"}</div>
      </div>

      <div className="mt-2">
        <h2 className="text-lg font-semibold text-left text-gray-900">
          AI-powered form generation
        </h2>
        <p className="mt-2 text-sm text-left text-gray-600">
          AI Forms understands your needs and generates tailored forms based on
          your specific requirements.
        </p>
      </div>

      <div className="mt-4 hover:cursor-pointer">
        <a
          href="https://buildform.featurebase.app/changelog"
          target="_blank"
          className="flex items-center gap-2 text-sm font-medium text-blue-600 hover:cursor-pointer"
        >
          <span>Check the update log</span>
          <RightOutlined />
        </a>
      </div>
    </div>
  );
};

export default FeatureCard;
