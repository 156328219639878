import React, { useEffect, useState } from "react";

import {
  ColumnDef,
  ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table.tsx";
import { DataTableToolbar } from "./DataTableToolbar.tsx";
import SkeletonTable from "./Skeleton-Table.tsx";

import { DEFAULT_PAGINATION } from "../../Api/usePagination.ts";

import { useFormSubmissionsContext } from "../../Contexts/FormSubmissionsContext.tsx";

import { useInView } from "react-intersection-observer";

type Pagination = {
  pageIndex: number;
  pageSize: number;
};

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  isLoaded?: boolean;
  total: number;
  onPaginationChange: (pagination: PaginationState) => void;
  onSortingChange: (sorting: SortingState) => void;
  onScrollEnd: () => void;
}

export function DataTable<TData, TValue>({
  columns,
  data,
  isLoaded,
  total,
  onScrollEnd,
  onPaginationChange,
  onSortingChange,
}: DataTableProps<TData, TValue>) {
  const { selection, setSelectedFields } = useFormSubmissionsContext();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0, //initial page index
    pageSize: DEFAULT_PAGINATION.size as number, //default page size
  });
  const [rowSelection, setRowSelection] = React.useState({});
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnFiltersChange: setColumnFilters,
    onRowSelectionChange: setRowSelection,
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    state: {
      sorting,
      columnFilters,
      pagination,
      rowSelection,
    },
    rowCount: total,
    manualPagination: true,
    autoResetPageIndex: false, //turn off auto reset of pageIndex
    onPaginationChange: setPagination, //update the pagination state when internal APIs mutate the pagination state
  });

  useEffect(() => {
    const selectedRows = table
      .getFilteredSelectedRowModel()
      .rows.map((row) => ({
        // @ts-ignore
        id: row.original.id,
        // @ts-ignore
        type: row.original?.type,
        // @ts-ignore
        title: row.original?.title,
      }));
    setSelectedFields(selectedRows);
  }, [rowSelection]);

  useEffect(() => {
    if (selection === "all") {
      table.toggleAllRowsSelected();
    } else {
      table.setRowSelection({});
    }
  }, [selection]);

  const { ref } = useInView({
    triggerOnce: true, // Trigger only once when the element comes into view
    onChange: (inView) => {
      if (inView) {
        onScrollEnd();
      }
    },
  });

  return (
    <div className="space-y-4 relative">
      <DataTableToolbar
        table={table}
        className="fixed z-[50] bg-white top-3 left-3 right-3"
      />
      {isLoaded ? (
        <div>
          <div className="rounded-md border h-[calc(100vh-140px)] overflow-auto fixed top-14 left-3 right-3 bottom-0">
            <Table className="h-fit w-fit">
              <TableHeader>
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <TableHead
                          key={header.id}
                          className="sticky top-0 bg-white border-r first:border-none last:border-r text-xs"
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </TableHead>
                      );
                    })}
                  </TableRow>
                ))}
              </TableHeader>
              <TableBody>
                {table.getRowModel().rows?.length ? (
                  table.getRowModel().rows.map((row) => (
                    <TableRow
                      key={row.id}
                      data-state={row.getIsSelected() && "selected"}
                      className="h-[40px]"
                    >
                      {row.getVisibleCells().map((cell) => (
                        <TableCell
                          key={cell.id}
                          className="border-r first:border-none last:border-r text-xs"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={columns.length}
                      className="h-24 text-center"
                    >
                      No results.
                    </TableCell>
                  </TableRow>
                )}
                {table.getRowModel().rows?.length > 0 && (
                  <div ref={ref} style={{ height: "20px", width: "100%" }} />
                )}
              </TableBody>
            </Table>
          </div>
          {/* <DataTablePagination
            table={table}
            pagination={pagination}
            onPaginationChange={onPaginationChange}
          /> */}
        </div>
      ) : (
        <SkeletonTable className="fixed top-10 left-3 right-3 bottom-0" />
      )}
    </div>
  );
}
