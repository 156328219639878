import React, { useState, useEffect, useRef } from "react";
import { io } from "socket.io-client";
import { LiveFadeAnimatedText } from "../Animated/FadeAnimatedText";
import { useFormContext } from "../../Contexts/FormContext";
import { motion } from "framer-motion";
const SOCKET_SERVER_URL = process.env.REACT_APP_SERVICE_URL;
const SOCKET_PATH = "/statements";
import { getDynamicTextTitle } from "../../Constants/styles";

const serviceType = "production";

const AIStatement = ({ field }) => {
  const {
    signature,
    setShowNextButton,
    data,
    responseId,
    formTheme,
    updateAnswers,
  } = useFormContext();
  setShowNextButton(false);
  const [textArray, setTextArray] = useState([]);
  const [isConnected, setIsConnected] = useState(false);
  const [error, setError] = useState(null);
  const socketRef = useRef(null);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [getneratedStatement, setGeneratedStatement] = useState("");

  isCompleted ? setShowNextButton(true) : setShowNextButton(false);

  useEffect(() => {
    socketRef.current = io(SOCKET_SERVER_URL, {
      transports: ["websocket"],
      auth: {
        service: serviceType,
        signature: signature,
      },
      path: SOCKET_PATH,
    });

    const socket = socketRef.current;

    socket.on("connect", () => {
      setIsConnected(true);
      setIsCompleted(false);
      setError(null);
    });

    // Emit the generate_statement event to the server
    socket.emit("statement", {
      response_id: responseId,
      form_id: data?.id,
      ai_statement_field_id: field?.id,
    });

    socket.on("response", (data) => {
      setTextArray((prevArray) => [...prevArray, data?.chunk]);
      setIsLoading(false);
      if (data?.status === "completed") {
        setIsCompleted(true);
      }
      setGeneratedStatement(data?.generated_content);
    });

    socket.on("disconnect", () => {
      setIsConnected(false);
      setShowNextButton(false);
      setIsCompleted(false);
    });

    socket.on("connect_error", (err) => {
      setError("Connection error");
      console.error("Connection error: ", err);
    });

    socket.on("connect_timeout", () => {
      setError("Connection timeout");
      console.error("Connection timeout");
    });

    socket.on("error", (err) => {
      setError("An error occurred");
      console.error("Error: ", err);
    });

    return () => {
      socket.disconnect();
    };
  }, [signature, field?.id]);

  useEffect(() => {
    if (isCompleted && textArray?.length > 0) {
      const answer = getneratedStatement;
      updateAnswers(field?.id, null, answer);
    }
  }, [isCompleted, textArray, updateAnswers, field?.id]);

  if (isLoading) {
    return (
      <motion.div style={{ textAlign: "left" }} layout="position">
        <motion.span
          initial={{ opacity: 0 }}
          animate={{ opacity: [0, 1, 0] }}
          transition={{ repeat: Infinity, duration: 1 }}
          style={{
            color: formTheme?.text_color,
            fontSize: getDynamicTextTitle(formTheme?.text_size),
            marginLeft: "5px",
          }}
        >
          |
        </motion.span>
      </motion.div>
    );
  }

  return (
    <div>
      <LiveFadeAnimatedText textArray={textArray} />
    </div>
  );
};

export default AIStatement;
