import WebFont from "webfontloader";

const createFontUrl = (family, variants) => {
  const variantString = variants.map((variant) => `wght@${variant}`).join(";");
  return `https://fonts.googleapis.com/css2?family=${family.replace(
    / /g,
    "+"
  )}:${variantString}&display=swap`;
};

export const loadFont = (font) => {
  if (!font || !font?.family || !font?.variants) {
    console.error("Invalid font data");
    return Promise.reject(new Error("Invalid font data"));
  }

  const url = createFontUrl(font?.family, font?.variants);

  return new Promise((resolve, reject) => {
    WebFont.load({
      google: {
        families: [`${font.family}:${font.variants.join(",")}`],
      },
      active: () => {
        resolve(font);
      },
      inactive: () => {
        console.warn(`Failed to load font: ${font.family}`);
        resolve(null); // Resolve with null instead of rejecting
      },
      fontinactive: (familyName, fvd) => {
        console.warn(`Font ${familyName} is inactive (FVD: ${fvd})`);
        resolve(null); // Resolve with null instead of rejecting
      },
    });
  });
};

export const applyFont = (font) => {
  if (!font || !font.family) {
    console.error("Invalid font object in applyFont");
    return '"sans-serif"'; // Return a fallback font
  }
  return `"${font.family}", sans-serif`;
};

// Function to load multiple fonts and filter out inactive ones
export const loadMultipleFonts = async (fonts) => {
  const loadedFonts = await Promise.all(fonts.map(loadFont));
  return loadedFonts.filter(Boolean); // Filter out null values (inactive fonts)
};
