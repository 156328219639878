import React, { useState, useEffect, useRef, useCallback } from "react";

import "@blocknote/shadcn/style.css";
import "@blocknote/core/fonts/inter.css";
import { BlockNoteView } from "@blocknote/shadcn";
import {
  DefaultReactSuggestionItem,
  SuggestionMenuController,
  useCreateBlockNote,
} from "@blocknote/react";
import {
  createReactStyleSpec,
  createReactInlineContentSpec,
} from "@blocknote/react";
import {
  Block,
  BlockNoteSchema,
  defaultInlineContentSpecs,
  filterSuggestionItems,
} from "@blocknote/core";

import * as Card from "../../ui/card.tsx";
import FormButton from "../FormButton.tsx";
import { Button } from "../../ui/button.tsx";
import { Skeleton } from "../../ui/skeleton.tsx";
import { motion } from "framer-motion";

import { useFormEditorContext } from "../../../Contexts/FormEditorContext.jsx";

import {
  QUESTION_OBJECTS,
  QUESTION_TYPES,
} from "../../../Constants/questionTypes.js";

import { SparklesIcon, XIcon } from "lucide-react";

import "./styles.css";

import { io } from "socket.io-client";

import clsx from "clsx";

import { useAuth } from "@clerk/clerk-react";

const SOCKET_SERVER_URL = process.env.REACT_APP_SERVICE_URL;
const SOCKET_PATH = "/statements";

const service = "preview";

const template = "custom_jwt_template";

function AiStatement() {
  const {
    fields,
    editorField,
    selectedField,
    setEditorField,
    setFields,
    form,
    theme,
  } = useFormEditorContext();
  const socketRef = useRef<any>(null);
  const { getToken } = useAuth();
  const [textArray, setTextArray] = useState<Array<string>>([]);
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [preview, setPreview] = useState<boolean>(false);
  const [blocks, setBlocks] = useState<Block[]>([]);
  const [token, setToken] = useState<string | null>(null);
  const editor = useCreateBlockNote({
    schema,
    trailingBlock: false,
    defaultStyles: false,
    dictionary: {
      // @ts-ignore
      placeholders: {
        default: "Your question here. Recall questions with @",
        heading: "Your question here. Recall questions with @",
      },
    },
    // initialContent: [
    //   {
    //     type: "paragraph",
    //     content: "Your question here. Recall questions with @",
    //   },
    // ],
  });

  // Debounce function to optimize frequent calls
  const debounce = (func, delay) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => func(...args), delay);
    };
  };

  // Save changes to `fields` (debounced)
  const saveChanges = useCallback((fieldId, key, subkey, value) => {
    setFields((prevFields) => {
      const fieldIndex = prevFields.findIndex((field) => field.id === fieldId);

      if (fieldIndex === -1) return prevFields;

      const updatedField = {
        ...prevFields[fieldIndex],
        [key]: subkey
          ? {
              ...prevFields[fieldIndex][key],
              [subkey]: value,
            }
          : value,
      };

      return [
        ...prevFields.slice(0, fieldIndex),
        updatedField,
        ...prevFields.slice(fieldIndex + 1),
      ];
    });
  }, []);
  const debouncedSaveChanges = debounce(saveChanges, 300);

  const updateField = useCallback(
    (fieldId, key, subkey, value) => {
      // Update `editorField` immediately for a responsive UI
      setEditorField((prev) => {
        if (subkey) {
          return {
            ...prev,
            [key]: {
              ...prev[key],
              [subkey]: value,
            },
          };
        } else {
          return {
            ...prev,
            [key]: value,
          };
        }
      });

      // Debounced update for `fields`
      debouncedSaveChanges(fieldId, key, subkey, value);
    },
    [debouncedSaveChanges]
  );

  useEffect(() => {
    if (blocks && blocks.length > 0) {
      const backendFormat = convertToBackendFormat(blocks);
      // Avoid triggering unnecessary updates by checking current state
      if (editorField?.title !== backendFormat) {
        updateField(editorField?.id, "title", null, backendFormat);
      }
    }
  }, [blocks, editorField?.id, editorField?.title, updateField]);

  const init = () => {
    if (editorField?.title) {
      const blockNoteFormat = convertToBlockNoteFormat(
        editorField.title,
        fields
      );
      // Update the content after finding and replacing field references
      const updatedContent = blockNoteFormat.map((block) => {
        if (block.content) {
          // @ts-ignore
          block.content = block.content.map((item) => {
            if (item.type === "mention" && item.props.user) {
              const fieldId = item.props.user.id;
              const fieldData = findFieldById(fields, fieldId);
              if (fieldData) {
                return {
                  ...item,
                  props: {
                    user: fieldData,
                  },
                };
              }
            }

            return item;
          });
        }

        return block;
      });

      // @ts-ignore
      editor.replaceBlocks(editor.document, updatedContent);
    }
  };

  // Initialize editor with backend format
  useEffect(() => {
    init();
  }, []);

  // First useEffect to handle token initialization
  useEffect(() => {
    const initializeToken = async () => {
      try {
        const newToken = await getToken({ template });
        setToken(newToken);
      } catch (error) {
        console.error("Error getting token:", error);
        setError("Failed to initialize token");
      }
    };

    initializeToken();
  }, [template]);

  const initializeSocket = () => {
    if (socketRef.current) {
      socketRef.current.disconnect();
    }

    // Initialize the socket
    socketRef.current = io(SOCKET_SERVER_URL, {
      transports: ["websocket"],
      auth: {
        token: token,
        service: service,
      },
      path: SOCKET_PATH,
    });

    const socket = socketRef.current;

    // Attach event listeners
    socket.on("connect", () => {
      setIsConnected(true);
      setIsCompleted(false);
      setError(null);
    });

    socket.on("response", (data) => {
      setTextArray((prevArray) => [...prevArray, data?.chunk]);
      setIsLoading(false);
      setPreview(true);
      if (data?.status === "done") {
        setIsCompleted(true);
      }
    });

    socket.on("disconnect", () => {
      setIsConnected(false);
      setIsCompleted(true);
    });

    socket.on("connect_error", (err) => {
      setError("Connection error");
      console.error("Connection error: ", err);
    });

    socket.on("connect_timeout", () => {
      setError("Connection timeout");
      console.error("Connection timeout");
    });

    socket.on("error", (err) => {
      setError("An error occurred");
      console.error("Error: ", err);
    });
  };

  const emitPreviewStatement = () => {
    setTextArray([]); // Clear the current text array
    if (socketRef.current) {
      socketRef.current.emit("statement", {
        form_id: form?.id,
        workspace_id: sessionStorage.getItem("selectedWorkSpace"),
        form_fields: fields,
        ai_statement_field_id: editorField?.id,
      });
    }
  };

  const handlePreviewClick = () => {
    if (!isConnected) {
      initializeSocket();
    }

    setIsLoading(true);
    emitPreviewStatement();
  };

  // Cleanup socket connection when the component unmounts
  useEffect(() => {
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
        socketRef.current = null;
      }
    };
  }, []);

  return (
    <div className="flex flex-col w-full max-w-[606px]">
      <div className="rounded-[12px] p-4 min-h-[150px] bg-gradient-to-r from-[rgba(103,52,255,0.05)] to-[rgba(65,163,255,0.05)]">
        <div className="bg-white rounded-[12px] py-1 px-2 flex flex-row items-center gap-1 w-fit text-xs">
          <SparklesIcon className="w-3 h-3 text-[#191B33]" />
          Buildform AI
        </div>

        {isLoading && (
          <div className="mt-4 space-y-2">
            <Skeleton className="w-10/12 h-[20px] rounded-full bg-gradient-to-r from-[#EFEAFF] to-[#FAEFFC]" />
            <Skeleton className="w-8/12 h-[20px] rounded-full bg-gradient-to-r from-[#EFEAFF] to-[#FAEFFC]" />
            <Skeleton className="w-11/12 h-[20px] rounded-full bg-gradient-to-r from-[#EFEAFF] to-[#FAEFFC]" />
          </div>
        )}
        {!preview && !isLoading && (
          <BlockNoteView
            editor={editor}
            className="min-w-[300px] bg-transparent p-0 text-[12px] mt-4"
            formattingToolbar={false}
            linkToolbar={false}
            sideMenu={false}
            slashMenu={false}
            emojiPicker={false}
            filePanel={false}
            tableHandles={false}
            theme="light"
            autoFocus={true}
            shadCNComponents={{
              // Pass modified ShadCN components from your project here.
              // Otherwise, the default ShadCN components will be used.
              Card,
            }}
            data-theming-css-demo
            onChange={() => {
              setBlocks(editor.document as any);
            }}
          >
            <SuggestionMenuController
              triggerCharacter={"@"}
              getItems={async (query) =>
                // Gets the mentions menu items
                filterSuggestionItems(
                  getMentionMenuItems(editor, fields, selectedField),
                  query
                )
              }
              suggestionMenuComponent={CustomAtMenu}
            />
          </BlockNoteView>
        )}

        {preview && textArray && textArray.length > 0 && (
          <motion.div
            style={{ textAlign: "left" }}
            layout="position"
            className="min-w-[300px] mt-4"
          >
            {textArray?.map((word, idx) => {
              return (
                <motion.span
                  key={word + idx}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5 }}
                  style={{
                    color: theme?.text_color,
                  }}
                  className={"text-2xl font-bold"}
                >
                  {word}
                </motion.span>
              );
            })}
          </motion.div>
        )}
      </div>
      <div className="flex items-end gap-3">
        <FormButton className={clsx("mt-3")}>Next</FormButton>
        {isCompleted && preview && (
          <Button
            variant="ghost"
            className={clsx("text-[#F44336] hover:bg-transparent px-0")}
            onClick={() => setPreview(false)}
          >
            End Preview
          </Button>
        )}
        {!preview && (
          <Button
            variant="ghost"
            className={clsx("text-[#2563EB] hover:bg-transparent px-0")}
            onClick={handlePreviewClick}
          >
            Preview Output
          </Button>
        )}
      </div>
    </div>
  );
}

export default AiStatement;

// Custom component to replace the default @ Menu.
function CustomAtMenu(props: SuggestionMenuProps<DefaultReactSuggestionItem>) {
  return (
    <div className="bg-white rounded-[8px] p-2 shadow-lg max-w-[190px]">
      {props.items.map((item, index) => (
        <div
          key={index}
          className={`flex flex-row items-center gap-2.5 cursor-pointer hover:bg-gray-100 p-2 rounded-[8px] `}
          onClick={() => {
            props.onItemClick?.(item);
          }}
        >
          {item.icon}
          <span className="capitalize truncate">{item.title}</span>
        </div>
      ))}
    </div>
  );
}

// The Mention inline content.
export const Mention = createReactInlineContentSpec(
  {
    type: "mention",
    propSchema: {
      user: {
        default: "Unknown",
      },
    },
    content: "none",
  },
  {
    render: (props) => {
      const field = props.inlineContent.props.user as any;
      // If the field is an empty string, it means the mention has been removed. :-)
      const hasRemoved = typeof field === "string" && field === "";
      if (hasRemoved) return null;

      return (
        <MentionComponent
          title={field?.title || field?.label}
          type={field?.type}
          onRemove={() => {
            // Remove the mention.
            props.updateInlineContent({
              type: "mention",
              props: {
                user: "",
              },
            });
          }}
        />
      );
    },
  }
);

type MentionComponentProps = {
  title: string;
  type: string;
  onRemove: () => void;
};

function MentionComponent({ title, type, onRemove }: MentionComponentProps) {
  const object = QUESTION_OBJECTS[type];
  return (
    <div
      className="flex items-center justify-center gap-1 w-fit"
      style={{
        backgroundColor: "#F1F2F4", // object?.color,
        color: "#091E42", // object?.textColor,
        borderRadius: "6px",
        padding: "6px 8px",
        fontSize: "12px",
        fontWeight: 400,
      }}
    >
      {truncateString(title)}
      <Button
        variant="ghost"
        className="flex items-center justify-center w-4 h-4 p-0 bg-transparent hover:bg-transparent"
        onClick={(e) => {
          e.preventDefault();
          onRemove();
        }}
      >
        <XIcon className="w-3 h-3" />
      </Button>
    </div>
  );
}

// Function which gets all users for the mentions menu.
const getMentionMenuItems = (
  editor: typeof schema.BlockNoteEditor,
  fields: any[],
  currentField: any
): DefaultReactSuggestionItem[] => {
  if (!fields || !Array.isArray(fields)) {
    return [];
  }

  const currentFieldIndex = fields?.findIndex(
    (field) => field?.id === currentField?.id
  );
  // Split the fields into two arrays, before and after the current field.
  // Only show the fields before the current field in the mentions menu.
  const beforeCurrentField = fields?.slice(0, currentFieldIndex);
  return beforeCurrentField
    ?.filter(
      (field) =>
        field?.type !== QUESTION_TYPES.AI_STATEMENT &&
        field?.type !== QUESTION_TYPES.SECTION
    )
    ?.map((field, index) => ({
      title: truncateString(field?.title) || field?.label,
      icon: <DropdownMenuIcon type={field?.type} index={index + 1} />,
      onItemClick: () => {
        editor.insertInlineContent([
          {
            type: "mention",
            props: {
              user: field,
            },
          },
          " ", // add a space after the mention
        ]);
      },
    }));
};

function DropdownMenuIcon({ type, index }: { type: string; index: number }) {
  const object = QUESTION_OBJECTS[type];
  return (
    <div
      className="flex flex-row items-center gap-[7px]"
      style={{
        fontSize: "8px",
        borderRadius: "4px",
        padding: "5px",
        backgroundColor: "#F1F2F4", // object?.color,
        color: "#091E42", // object?.textColor,
      }}
    >
      {index > 9 ? "" : "0"}
      {index}
      {object?.icon}
    </div>
  );
}

// The Font style.
export const Font = createReactStyleSpec(
  {
    type: "font",
    propSchema: "string",
  },
  {
    render: (props) => (
      <span style={{ fontFamily: props.value }} ref={props.contentRef} />
    ),
  }
);

const schema = BlockNoteSchema.create({
  inlineContentSpecs: {
    // Adds all default inline content.
    ...defaultInlineContentSpecs,
    // Adds the mention tag.
    mention: Mention,
  },
  styleSpecs: {
    font: Font,
  },
});

function truncateString(str: string, num: number = 30) {
  if (str?.length <= num) {
    return str;
  }
  return str?.slice(0, num) + "...";
}

type MentionUser = {
  id: string;
  title: string;
  type: string;
  // ... other properties
};

export const convertToBackendFormat = (blocks: Block[]): string => {
  if (!blocks || !Array.isArray(blocks)) {
    return "";
  }

  let result = "";

  blocks.forEach((block) => {
    if (block.content) {
      // @ts-ignore
      block.content.forEach((item) => {
        if (item.type === "text") {
          result += item.text;
        } else if (item.type === "mention" && item.props.user) {
          const user = item.props.user as MentionUser;
          result += `{{field:${user.id}}}`;
        }
      });
    }
  });

  return result;
};

export const convertToBlockNoteFormat = (
  text: string | null | undefined,
  fields: any[]
): Block[] => {
  // Handle null, undefined, or non-string input
  if (!text || typeof text !== "string") {
    return [
      {
        type: "paragraph",
        props: {
          textColor: "default",
          backgroundColor: "default",
          textAlignment: "left",
        },
        content: [
          {
            type: "text",
            text: "",
            styles: {},
          },
        ],
        children: [],
      },
    ];
  }

  const regex = /{{field:([^}]+)}}/g;
  const parts = text.split(regex);

  const content: any[] = [];

  parts.forEach((part, index) => {
    if (index % 2 === 0) {
      // Regular text
      if (part) {
        content.push({
          type: "text",
          text: part,
          styles: {},
        });
      }
    } else {
      // Field reference
      const fieldId = part;
      const fieldData = fields?.find((field) => field.id === fieldId);
      // Field reference
      if (fieldData) {
        content.push({
          type: "mention",
          props: {
            user: {
              id: part,
              // You'll need to look up the full field data from your fields array
              title: `Field ${part}`,
              type: "short_text",
            },
          },
        });
      }
    }
  });

  return [
    {
      type: "paragraph",
      props: {
        textColor: "default",
        backgroundColor: "default",
        textAlignment: "left",
      },
      content,
      children: [],
    },
  ];
};

export const findFieldById = (fields: any[], id: string) => {
  if (!fields || !Array.isArray(fields)) {
    return null;
  }
  return fields.find((field) => field.id === id);
};
