import React, { useMemo, useState, useEffect } from "react";

import { Input } from "../ui/input.tsx";
import {
  Command,
  CommandGroup,
  CommandItem,
  CommandList,
} from "../ui/command.tsx";
import { Popover, PopoverAnchor, PopoverContent } from "../ui/popover.tsx";

import { Command as CommandPrimitive } from "cmdk";

import { useFormEditorContext } from "../../Contexts/FormEditorContext.jsx";

import { Countries } from "../../Constants/countries.ts";

import useStyles from "../../Hooks/useStyles.ts";

import { cn } from "../../lib/utils.js";

import { Check } from "lucide-react";

type CountryDropdownProps = React.HTMLAttributes<HTMLDivElement> & {
  defaultValue?: string | null;
};

export default function CountryDropdown({
  defaultValue = "US",
  ...props
}: CountryDropdownProps) {
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState(() => {
    const country = Countries.find((country) => country.code === defaultValue);
    return country ? `${country.emoji} ${country.name}` : "";
  });
  const [selectedCountry, setSelectedCountry] = useState("");
  const { theme, device } = useFormEditorContext();
  const { getStyles } = useStyles();

  const items = useMemo(() => {
    return Countries.map((country) => ({
      code: country.code,
      label: country.name,
      value: country.name,
      flag: country.image,
      dialingCode: country.dial_code,
      emoji: country.emoji,
    }));
  }, [Countries]);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <Command
        shouldFilter={true}
        className={cn(
          "p-0 m-0 h-fit rounded-none bg-transparent focus-visible:ring-0 focus-visible:ring-offset-0 border-none active:ring-0 outline-none",
          props.className
        )}
      >
        <PopoverAnchor asChild>
          <CommandPrimitive.Input
            asChild
            value={searchValue}
            onValueChange={setSearchValue}
            onKeyDown={(e) => setOpen(e.key !== "Escape")}
            onMouseDown={() => setOpen((open) => !!searchValue || !open)}
            onFocus={() => setOpen(true)}

            // onBlur={onInputBlur}
          >
            <Input
              style={{
                color: theme?.answer_text_color,
                fontSize: getStyles("h4", theme?.text_size).fontSize,
                fontFamily: `${theme?.font_family?.family}, ${theme?.font_family?.category}`,
              }}
              className={cn(
                `p-0 h-fit text-md text-left bg-transparent focus-visible:ring-0 focus-visible:ring-offset-0 border-x-0 border-t-0 rounded-none border-b border-[#B3B9C4] outline-none active:ring-0`,
                device === "mobile" ? "mt-4" : "mt-6"
              )}
            />
          </CommandPrimitive.Input>
        </PopoverAnchor>
        {!open && <CommandList aria-hidden="true" className="hidden" />}
        <PopoverContent
          asChild
          onOpenAutoFocus={(e) => e.preventDefault()}
          onInteractOutside={(e) => {
            if (
              e.target instanceof Element &&
              e.target.hasAttribute("cmdk-input")
            ) {
              e.preventDefault();
            }
          }}
          className="w-[--radix-popover-trigger-width] p-0"
        >
          <CommandList>
            {items.length > 0 ? (
              <CommandGroup>
                {items.map((option) => (
                  <CommandItem
                    asChild
                    key={option.value}
                    value={option.emoji + " " + option.label}
                    onMouseDown={(e) => e.preventDefault()}
                    onSelect={(value) => {
                      setSelectedCountry(value);
                      setSearchValue(value);
                      setOpen(false);
                    }}
                  >
                    <div className="flex flex-row items-center gap-2">
                      <Check
                        className={cn(
                          "mr-2 h-4 w-4",
                          selectedCountry === option.label
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                      {/* <img src={option.flag} width={32} height={32} /> */}
                      <p>{option.emoji}</p>
                      <p>
                        {option.label} ({option.dialingCode})
                      </p>
                    </div>
                  </CommandItem>
                ))}
              </CommandGroup>
            ) : null}
          </CommandList>
        </PopoverContent>
      </Command>
    </Popover>
  );
}
