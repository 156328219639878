import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { sideFade, sideFadeRight } from "../Constants/animations";
import { ConfigProvider } from "antd";
import EdiotorNav from "../Components/MyEditor/EdiotorNav.tsx";

const CommonLayout = ({
  children,
  sidebar,
  mainContentClassName,
  editor = false,
}) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: "Inter, sans-serif",
        },
      }}
    >
      <main className="flex flex-col h-screen overflow-hidden">
        {/* Nav section */}
        {editor && (
          <section className="flex items-center flex-shrink-0 w-full pr-2 pl-[15px] py-5 h-14">
            <EdiotorNav />
          </section>
        )}

        {/* Main content container */}
        <div className="flex flex-1 min-h-0 overflow-hidden">
          {/* Sidebar section */}
          <AnimatePresence mode="wait">
            <motion.div {...sideFade} className="overflow-y-auto no-scrollbar">
              {sidebar}
            </motion.div>
          </AnimatePresence>

          {/* Main section */}
          <AnimatePresence mode="wait">
            <motion.div
              {...sideFadeRight}
              className={`
              flex-1 
              w-full 
              overflow-y-auto 
              pr-2 
              ${mainContentClassName} 
              ${editor ? "pb-2" : "py-2"}
            `}
            >
              {children}
            </motion.div>
          </AnimatePresence>
        </div>
      </main>
    </ConfigProvider>
  );
};

export default CommonLayout;
