import { IForm } from "../Redux/slices/formSlice";

export const newFormProps = (formName: string): IForm => {
  return {
    name: formName,
    type: "quiz",
    welcomeScreen: {
      title: "Welcome to our form",
      type: "url_redirect",
      description: "This is a description of the form",
      button_text: "Start",
      layout: {
        type: "float",
        attachment: {
          type: "image",
          href: "",
        },
        placement: "right",
      },
    },
    endScreen: {
      title: "Thank you",
      type: "url_redirect",
      description: "This is a description of the form end",
    },
    fields: [],
    settings: {
      language: "en",
      progress_bar: "percentage",
      meta: {
        title: "Bootcamp Application Form",
        allow_indexing: true,
        description: null,
        image: {
          link: formImage(),
        },
      },
      hide_navigation: false,
      is_public: true,
      is_trial: false,
      show_progress_bar: true,
      are_uploads_public: false,
      show_time_to_complete: false,
      show_number_of_submissions: true,
      show_cookie_consent: false,
      show_question_number: true,
      show_key_hint_on_choices: true,
      autosave_progress: true,
      free_form_navigation: false,
      accept_responses: true,
    },
    hidden: null,
    links: {
      display: null,
      responses: null,
      custom: null,
    },
    is_ai_form: false,
    ai_form: null,
  };
};

const formImage = () => {
  // Array of image URLs
  const images = [
    "https://buildform-file-uploads-v2.s3.ap-southeast-1.amazonaws.com/8392859fb193b51771d9e36cc9920c7d.jpeg",
    "https://buildform-file-uploads-v2.s3.ap-southeast-1.amazonaws.com/9d2033066fdf2562b08610ab7663b769.jpeg",
    "https://buildform-file-uploads-v2.s3.ap-southeast-1.amazonaws.com/f5c2a6b9f27540e291644e59cff69b5f.jpeg",
    "https://buildform-file-uploads-v2.s3.ap-southeast-1.amazonaws.com/1f27e2e6c0523e4f9eb2c889c7f00057.jpeg",
    "https://buildform-file-uploads-v2.s3.ap-southeast-1.amazonaws.com/3f501b5633d3e2ac1dd01fb9627d4564.jpeg",
    "https://buildform-file-uploads-v2.s3.ap-southeast-1.amazonaws.com/35f8a7d1e887a692d0c0e53aba094447.jpeg",
    "https://buildform-file-uploads-v2.s3.ap-southeast-1.amazonaws.com/07a383f7008a008d0e9af98343e3cf6a.jpeg",
  ];

  // Randomly select an image
  const randomIndex = Math.floor(Math.random() * images.length);
  return images[randomIndex];
};
