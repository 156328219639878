import * as React from "react";
import { cn } from "../../lib/utils";
import { useFormEditorContext } from "../../Contexts/FormEditorContext";

type ResizableTextareaProps = React.HTMLAttributes<HTMLTextAreaElement> & {
  value: string;
  placeholder?: string;
  onChange: (value: string) => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
};

export function ResizableTextarea({
  className,
  style,
  value,
  placeholder,
  onChange,
  onKeyUp,
  ...props
}: ResizableTextareaProps) {
  const ref = React.useRef<HTMLTextAreaElement>(null);
  const { device } = useFormEditorContext();
  const timeoutRef = React.useRef<NodeJS.Timeout>();
  const isAdjustingRef = React.useRef(false);

  const adjustHeight = React.useCallback(() => {
    if (!ref.current || isAdjustingRef.current) return;

    isAdjustingRef.current = true;
    requestAnimationFrame(() => {
      if (!ref.current) return;

      // Store the current scroll position
      const scrollPos = window.scrollY;

      // Reset height to minimum
      ref.current.style.height = "auto";

      // Calculate new height
      const computedStyle = window.getComputedStyle(ref.current);
      const lineHeight = parseInt(computedStyle.lineHeight) || 24; // fallback line height
      const newHeight = Math.max(lineHeight, ref.current.scrollHeight);

      // Apply height with max constraint
      ref.current.style.height = `${Math.min(newHeight, 288)}px`;

      // Restore scroll position
      window.scrollTo(0, scrollPos);

      isAdjustingRef.current = false;
    });
  }, []);

  // Handle initial mount and value changes
  React.useEffect(() => {
    adjustHeight();
  }, [value, adjustHeight]);

  // Handle device changes
  React.useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    const adjustTimes = [0, 100, 300, 500];

    adjustTimes.forEach((delay) => {
      timeoutRef.current = setTimeout(adjustHeight, delay);
    });

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [device, adjustHeight]);

  // Handle window resize
  React.useEffect(() => {
    const handleResize = () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(adjustHeight, 100);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [adjustHeight]);

  const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e.target.value);
    adjustHeight();
  };

  const handleOnKeyUp = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    onKeyUp?.(e);
    adjustHeight();
  };

  return (
    <textarea
      ref={ref}
      className={cn(
        "p-0 m-0 h-fit text-2xl text-left font-bold bg-transparent focus-visible:ring-0 focus-visible:ring-offset-0 border-none outline-none overflow-hidden max-h-72 transition-height duration-200",
        className
      )}
      rows={1}
      placeholder={placeholder}
      style={{ ...style, resize: "none" }}
      value={value}
      onChange={handleOnChange}
      onKeyUp={handleOnKeyUp}
      {...props}
    />
  );
}
