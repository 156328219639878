import React from "react";

import { ResizableTextarea } from "../ui/resizable-textarea.tsx";

import useStyles from "../../Hooks/useStyles.ts";

import { useFormEditorContext } from "../../Contexts/FormEditorContext.jsx";
import { cn } from "../../lib/utils.js";

function EndScreen() {
  const { getStyles } = useStyles();
  const { endScreen, setEndScreen, theme, setChanged, device } =
    useFormEditorContext();

  const updateEndScreen = (key, value) => {
    setEndScreen({
      ...endScreen,
      [key]: value,
    });
    setChanged(true);
  };

  const getDeviceStyle = () => {
    if (device === "mobile") {
      return "p-5";
    }
    return "";
  };

  const deviceStyle = getDeviceStyle();

  return (
    <div
      className={cn("flex flex-col gap-0 w-full max-w-[606px]", deviceStyle)}
    >
      <ResizableTextarea
        placeholder="End Screen Title"
        value={endScreen?.title}
        onChange={(value) => updateEndScreen("title", value)}
        style={{
          color: theme?.text_color,
          fontSize: getStyles("h1", theme?.text_size).fontSize,
          fontWeight: getStyles("h1", theme?.text_size).fontWeight,
          fontFamily: `${theme?.font_family?.family}, ${theme?.font_family?.category}`,
        }}
        className="py-2 leading-tight"
      />
      <ResizableTextarea
        placeholder="Add End Screen Description (optional)"
        value={endScreen?.description}
        onChange={(value) => updateEndScreen("description", value)}
        style={{
          color: theme?.text_color,
          fontSize: getStyles("h2", theme?.text_size).fontSize,
          fontWeight: "400",
          fontFamily: `${theme?.font_family?.family}, ${theme?.font_family?.category}`,
        }}
      />
    </div>
  );
}

export default EndScreen;
