import React from "react";

import { ToggleGroup, ToggleGroupItem } from "../../ui/toggle-group.tsx";
import { ResizableTextarea } from "../../ui/resizable-textarea.tsx";
import FormButton from "../FormButton.tsx";

import useStyles from "../../../Hooks/useStyles.ts";

import { useFormEditorContext } from "../../../Contexts/FormEditorContext.jsx";

import { CheckIcon, XIcon } from "lucide-react";

export default function Legal() {
  const { getStyles } = useStyles();
  const { editorField, setEditorField, theme, setFields, device } =
    useFormEditorContext();

  const updateField = (fieldId, key, subkey, value) => {
    setFields((prevFields) => {
      const updatedFields = prevFields.map((field) => {
        if (field.id === fieldId) {
          if (subkey) {
            return {
              ...field,
              [key]: {
                ...field[key],
                [subkey]: value,
              },
            };
          } else {
            return {
              ...field,
              [key]: value,
            };
          }
        }
        return field;
      });
      return updatedFields;
    });

    setEditorField((prev) => {
      if (subkey) {
        return {
          ...prev,
          [key]: {
            ...prev[key],
            [subkey]: value,
          },
        };
      } else {
        return {
          ...prev,
          [key]: value,
        };
      }
    });
  };

  return (
    <div className="flex flex-col gap-0 w-full max-w-[606px]">
      <ResizableTextarea
        placeholder="Add question title"
        value={editorField?.title || ""}
        onChange={(value) => updateField(editorField?.id, "title", null, value)}
        style={{
          color: theme?.text_color,
          fontSize: getStyles("h2", theme?.text_size, device).fontSize,
          fontWeight: getStyles("h2", theme?.text_size, device).fontWeight,
          fontFamily: `${theme?.font_family?.family}, ${theme?.font_family?.category}`,
        }}
      />
      <ResizableTextarea
        placeholder="Add question description (Optional)"
        value={editorField?.properties?.description || ""}
        onChange={(value) =>
          updateField(editorField?.id, "properties", "description", value)
        }
        style={{
          color: theme?.text_color,
          fontSize: getStyles("h4", theme?.text_size, device).fontSize,
          fontWeight: getStyles("h4", theme?.text_size, device).fontWeight,
          fontFamily: `${theme?.font_family?.family}, ${theme?.font_family?.category}`,
        }}
        className="mt-2"
      />

      <ToggleGroup
        variant="outline"
        type="single"
        className="flex flex-row items-start justify-start gap-2 mt-6 p-0"
      >
        <ToggleGroupItem
          value="AGREE"
          aria-label={`Toggle Agree`}
          className="border-none p-0 hover:bg-inherit rounded-lg"
        >
          <ToggleButton type="AGREE" />
        </ToggleGroupItem>
        <ToggleGroupItem
          value="DISAGREE"
          aria-label={`Toggle Disagree`}
          className="border-none p-0 hover:bg-inherit rounded-lg"
        >
          <ToggleButton type="DISAGREE" />
        </ToggleGroupItem>
      </ToggleGroup>
      <FormButton className="mt-3">Next</FormButton>
    </div>
  );
}

type ToggleButtonProps = {
  type: "AGREE" | "DISAGREE";
};

function ToggleButton({ type }: ToggleButtonProps) {
  const { theme } = useFormEditorContext();
  const text = type === "AGREE" ? "Agree" : "Disagree";
  const Icon = type === "AGREE" ? CheckIcon : XIcon;
  return (
    <div
      className={`flex flex-row items-center gap-3 border border-[#DCDFE4] rounded-lg p-1 w-[150px] h-[40px]`}
    >
      <div className="flex items-center justify-center bg-[#F1F2F4] rounded-lg text-center w-8 h-8">
        <Icon
          style={{
            color: theme?.answer_text_color,
          }}
        />
      </div>
      <p
        style={{
          color: theme?.answer_text_color,
        }}
      >
        {text}
      </p>
    </div>
  );
}
