import React, { useRef, useState } from "react";
import { AutoComplete, Input, Typography } from "antd";
import { DownOutlined } from "@ant-design/icons";
import {
  answerInputTextSize,
  getInputStyle,
  selectOptionSize,
} from "../../Constants/styles";
import { useFormContext } from "../../Contexts/FormContext";
import { useCookies } from "react-cookie";
import { useCurrentCountry } from "../../Api/useCurrentCountry";
import KeyboardEventHandler from "react-keyboard-event-handler";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "../../Api/useApi.ts";
import { useFormEditorContext } from "../../Contexts/FormEditorContext.jsx";

export const AutoCompleteDropdown = (props) => {
  const { color, field, index, country = false } = props;
  const { countryCode } = useCurrentCountry();
  //const newTheme = props?.theme;
  const [cookies, setCookie] = useCookies([field?.id + "-open"]);
  const [value, setValue] = useState(props.value);
  const ref = useRef();
  const { formTheme } = useFormContext();
  const { theme } = useFormEditorContext();

  const isFormEditor = location.pathname.includes("form-editor");
  const constTheme = isFormEditor ? theme : formTheme;

  const editorTheme = {
    answer_text_color: constTheme?.answer_text_color,
    text_color: constTheme?.text_color,
  };

  const api = useApi();
  const { data } = useQuery({
    queryKey: ["countries"],
    queryFn: () => api.utils.getCountries(),
    staleTime: Infinity,
  });

  const COUNTRY_CHOICES = data?.data?.countries;

  const defaultCountry = COUNTRY_CHOICES?.find(
    (country) => country.country_code === countryCode
  );

  const editor = props?.editor;

  const isOpen = cookies[field?.id + "-open"] || false;
  const setOpen = (value) => {
    setCookie(field?.id + "-open", value);
  };

  const { styles, isSubQuestion } = useFormContext();

  const inputThemeForEditor = editor ? editorTheme : styles;

  const options = country
    ? COUNTRY_CHOICES?.map((o) => ({
        ...o,
        label: `${o?.country_flag} ${o?.name}`,
        value: `${o?.name}`,
      }))
    : props?.field?.properties?.choices?.map((o) => ({
        ...o,
        value: o?.label,
      }));
  const onChange = (value) => {
    setOpen(true);
    setValue(value);
  };

  /*  if (country && !countryCode) {
    // Render a loading state while the country code is being fetched
    return <div>Loading...</div>;
  } */

  //   const t = answerInputTextSize

  // console.log("options", options);

  return (
    <div style={{ width: "100%  " }}>
      <KeyboardEventHandler
        handleKeys={["tab"]}
        onKeyEvent={async (key, e) => {
          // setOpen(!isOpen);
          onChange();
          props?.onChange(
            options?.filter(
              (option) =>
                option?.value?.toUpperCase().indexOf(value.toUpperCase()) !== -1
            )[0]?.value
          );
          setTimeout(() => {
            ref?.current?.focus();
          }, 500);
        }}
        style={{ width: "100%  " }}
      >
        <AutoComplete
          ref={ref}
          onSelect={(value) => {
            setOpen(false);
            props.onChange(value);
          }}
          {...props}
          value={value}
          size="large"
          onChange={onChange}
          defaultValue={
            country && defaultCountry?.name
              ? `${defaultCountry?.country_flag} ${defaultCountry?.name}`
              : null
          }
          dropdownStyle={{
            backgroundColor: constTheme?.background_color,
          }}
          // defaultValue={country ? defaultCountry : null}
          onClick={() => setOpen(!isOpen)}
          // defaultActiveFirstOption
          open={isOpen}
          autoFocus={
            editor ? null : !isSubQuestion[0] || index == isSubQuestion[1]
          }
          suffixIcon={<DownOutlined />}
          variant="borderless"
          style={{ width: "100%", fontSize: null }}
          options={options}
          placeholder=""
          backfill={true}
          filterOption={(inputValue, option) =>
            option?.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }
          optionRender={(option) => {
            return (
              <Typography.Text
                style={{
                  color:
                    inputThemeForEditor?.answer_text_color ||
                    inputThemeForEditor?.text_color,
                  fontSize: selectOptionSize,
                }}
              >
                {option?.label}
              </Typography.Text>
            );
          }}
          defaultActiveFirstOption
          // popupMatchSelectWidth
        >
          <Input
            size={"large"}
            style={{
              width: "100%",
              ...getInputStyle(inputThemeForEditor, true),
              fontSize: answerInputTextSize,
            }}
            placeholder="Select"
          />
        </AutoComplete>
      </KeyboardEventHandler>
    </div>
  );
};
