import React, { useState } from "react";
import { AreaChart, Area, Tooltip, ResponsiveContainer } from "recharts";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "../../Api/useApi.ts";
import { useFormEditorContext } from "../../Contexts/FormEditorContext.jsx";

const items = [
  {
    key: "1",
    label: "Past 7 days",
    value: "7_days",
  },
  {
    key: "2",
    label: "Past 14 days",
    value: "14_days",
  },
  {
    key: "3",
    label: "Past 1 month",
    value: "1_month",
  },
  {
    key: "4",
    label: "Past 3 months",
    value: "3_months",
  },
  {
    key: "5",
    label: "Past 6 months",
    value: "6_months",
  },
  {
    key: "6",
    label: "Past 1 year",
    value: "1_year",
  },
];

const FormRepliesCard = () => {
  const { selectedWorkSpace } = useFormEditorContext();
  const [selectedDays, setSelectedDays] = useState("1");
  const [tooltipData, setTooltipData] = useState(null);

  const handleMouseMove = (e) => {
    if (e && e.activePayload && e.activePayload.length > 0) {
      setTooltipData(e.activePayload[0].payload);
    }
  };

  const handleMouseLeave = () => {
    setTooltipData(null);
  };

  const selectedItem = items.find((item) => item.key === selectedDays);

  // Extract the label and value from the selected item
  const selectedLabel = selectedItem?.label || "Selectable";
  const selectedValue = selectedItem?.value || "";

  const handleMenuClick = (e) => {
    setSelectedDays(e.key);
  };

  const api = useApi();

  const {
    data: statData,
    isSuccess,
    isFetching,
  } = useQuery({
    queryKey: ["stats", selectedWorkSpace, selectedValue],
    queryFn: () => api.dashboard.getStats(selectedWorkSpace, selectedValue),
    staleTime: Infinity,
    enabled: !!selectedWorkSpace,
  });

  const menu = (
    <Menu
      items={items}
      selectable
      selectedKeys={[selectedDays]}
      onClick={handleMenuClick}
    />
  );

  function reorderArrayFromLastToFirst(arr) {
    if (selectedDays === "5" || selectedDays === "6") {
      return arr;
    }
    return arr?.slice()?.reverse();
  }

  return (
    <div className="flex flex-col items-center gap-6 px-6 py-6 border shadow-sm rounded-2xl lg:col-span-2">
      <div className="flex items-end justify-between w-full">
        <div className="flex items-center gap-2 ">
          <h2 className="text-lg font-semibold tracking-tighter text-start text-zinc-500">
            Form Replies
          </h2>
          <h2 className="text-xl font-semibold">
            {" "}
            {tooltipData ? tooltipData?.responses : statData?.data?.count}
          </h2>
          <div className="flex items-end gap-2"></div>
        </div>
        <Dropdown overlay={menu}>
          <div className="  hover:cursor-pointer border  rounded-md px-1.5 py-1 flex gap-1.5 items-center text-xs">
            {selectedLabel || "Selectable"}
            <DownOutlined />
          </div>
        </Dropdown>
      </div>

      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          width={500}
          height={400}
          data={reorderArrayFromLastToFirst(statData?.data?.data)}
          margin={{
            top: 10,
            right: 0,
            left: 0,
            bottom: 0,
          }}
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
        >
          <defs>
            <linearGradient id="gradientFill" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#4D68EB" stopOpacity={0.2} />
              <stop offset="100%" stopColor="#ffffff" stopOpacity={0.2} />
            </linearGradient>
          </defs>
          <Tooltip
            content={
              <div className="flex flex-col text-sm text-gray-700 text-start">
                <p>{tooltipData?.date}</p>
                <p>{tooltipData?.responses}</p>
              </div>
            }
          />

          <Area
            type="monotone"
            dataKey="responses"
            stroke="#4D68EB"
            fill="url(#gradientFill)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default FormRepliesCard;
