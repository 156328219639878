"use client";

import * as React from "react";
import { format } from "date-fns";
import {
  CalendarDaysIcon,
  Calendar as CalendarIcon,
  ChevronDownIcon,
} from "lucide-react";
import { DateRange } from "react-day-picker";

import { cn } from "../../lib/utils";
import { Button } from "../../Components/ui/button.tsx";
import { Calendar } from "../../Components/ui/calendar.tsx";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../Components/ui/popover.tsx";

type DateRangePickerProps = React.HTMLAttributes<HTMLDivElement> & {
  date: DateRange | undefined;
  setDate: React.Dispatch<React.SetStateAction<DateRange | undefined>>;
};

export function DateRangePicker({
  className,
  date,
  setDate,
}: DateRangePickerProps) {
  return (
    <div className={cn("grid gap-2", className)}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={"outline"}
            className={cn(
              "min-w-[140px] h-[36px] justify-between text-left font-normal text-xs",
              !date && "text-muted-foreground"
            )}
          >
            <div className="flex flex-row gap-1 items-center">
              <CalendarDaysIcon className="w-3 h-3 mr-2 text-gray-600" />
              {date?.from ? (
                date.to ? (
                  <>
                    {format(date.from, "LLL dd, y")} -{" "}
                    {format(date.to, "LLL dd, y")}
                  </>
                ) : (
                  format(date.from, "LLL dd, y")
                )
              ) : (
                <span className="text-xs">All Time</span>
              )}
            </div>
            <ChevronDownIcon className="w-3 h-3 text-gray-600" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={date?.from}
            selected={date}
            onSelect={setDate}
            numberOfMonths={1}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}
