import { useSignIn, useClerk } from "@clerk/clerk-react";
import { Input } from "../ui/input.tsx";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../Constants/routes.js";
import CommonLayout from "./CommonLayout.tsx";
import OAuthSignIn from "./OAuthSignIn.tsx";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import {
  Form,
  FormField,
  FormItem,
  FormControl,
  FormMessage,
} from "../ui/form.tsx";

const signInSchema = z.object({
  email: z
    .string()
    .min(1, "Email field cannot be empty")
    .email("Please enter a valid email address"),
});

const LoginContent = () => {
  const { setVerified, setExpired, setActive } = useClerk();
  const navigate = useNavigate();
  const { signIn } = useSignIn();
  const [canResend, setCanResend] = useState<boolean>(false); // Disable resend button initially
  const [countdown, setCountdown] = useState<number>(60);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [isVerificationTrue, setIsVerificationTrue] = useState<boolean>(false);

  const form = useForm({
    resolver: zodResolver(signInSchema),
    defaultValues: {
      email: "",
    },
  });

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);

      // Cleanup the timer on component unmount
      return () => clearTimeout(timer);
    } else {
      setCanResend(true); // Allow resend when countdown reaches zero
    }
  }, [countdown, canResend]);

  // Get the email link flow functions
  const handleResend = (e) => {
    e.preventDefault();
    const data = form.getValues();
    setCanResend(false); // Disable resend initially
    setCountdown(60); // Restart countdown
    handleEmailSignIn(data);
  };

  const handleEmailSignIn = async (data) => {
    let email = data?.email;

    setLoading(true);
    setError("");
    try {
      // First create the sign-in
      const signInResponse = await signIn?.create({
        identifier: email,
      });

      // Check if we have supportedFirstFactors
      if (
        !signInResponse?.supportedFirstFactors ||
        !signInResponse?.supportedFirstFactors.length
      ) {
        throw new Error("No supported factors found");
      }

      // Find the email link factor
      const emailFactor = signInResponse?.supportedFirstFactors.find(
        (ff) => ff.strategy === "email_link" && ff.safeIdentifier === email
      );

      if (!emailFactor) {
        throw new Error("Email link sign in is not available");
      }
      if (emailFactor) {
        // navigate("/login?verification=true", { state: { email } });
        setCountdown(60);
        setLoading(false);
        setIsVerificationTrue(true);
      }

      const { emailAddressId } = emailFactor;
      // Start the email link flow with the required redirectUrl
      const res = await signIn?.createEmailLinkFlow().startEmailLinkFlow({
        emailAddressId: emailAddressId,
        redirectUrl: `${window.location.origin}/dashboard`,
      });

      // Check the verification result
      if (res?.firstFactorVerification) {
        const verification = res.firstFactorVerification;
        // console.log("Verification status:", verification.status); // Debug log

        if (verification.verifiedFromTheSameClient()) {
          setVerified(true);
          return;
        } else if (verification.status === "expired") {
          setExpired(true);
        }
      }

      if (res?.status === "complete") {
        setActive({ session: res?.createdSessionId });
        return;
      }
    } catch (err) {
      console.error("Error details:", err); // Debug log
      setError(err.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const handlePasskeySignIn = async () => {
    try {
      await signIn?.authenticateWithPasskey({
        flow: "discoverable",
      });
    } catch (err) {
      console.error("Passkey sign in failed");
    }
  };

  return (
    <CommonLayout>
      {!isVerificationTrue && (
        <>
          <div className="flex flex-col w-full ">
            <h1 className=" font-semibold text-[1.5rem]">
              Sign in to BuildForm AI
            </h1>
            <p className=" text-[#475467] font-normal text-[0.875rem]">
              Welcome back! Please enter your login details
            </p>
          </div>
          <div className="flex flex-col w-full gap-4 mt-[32px] ">
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(handleEmailSignIn)}
                className="w-full space-y-2"
              >
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Input
                          placeholder="Enter your email"
                          {...field}
                          className={
                            form.formState.errors.email
                              ? "border-[#F44336] focus-visible:ring-offset-0 focus-visible:outline-none focus-visible:ring-0"
                              : " focus:border-[#A1A1AA] border-[#E4E4E7] focus-visible:ring-offset-0 focus-visible:outline-none focus-visible:ring-0"
                          }
                        />
                      </FormControl>
                      <FormMessage className=" text-[#F44336]" />
                    </FormItem>
                  )}
                />
                {error &&
                  Object.keys(form?.formState?.errors || {}).length === 0 && (
                    <FormMessage className=" text-[#F44336]">
                      {error}
                    </FormMessage>
                  )}

                <button
                  className="inline-flex gap-x-1.5 items-center justify-center w-full h-10 px-4 py-2 mt-2 text-xs font-medium transition rounded-md whitespace-nowrap ring-offset-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-[#191B33] text-zinc-50"
                  type="submit"
                  disabled={loading}
                >
                  <span className="text-[16px] font-medium">
                    {loading ? "Sending link..." : "Continue with email ->"}
                  </span>
                </button>
              </form>
            </Form>
            <div className="flex items-center justify-center gap-x-1.5">
              <span className="text-sm font-medium ">
                Don't have an account?
              </span>
              <a
                href={ROUTES.SIGNUP}
                className="text-[#2563EB] text-sm font-medium  "
              >
                Create free account
              </a>
            </div>

            <div className="relative mt-2">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300"></div>
              </div>
              <div className="relative flex justify-center text-[12px]">
                <span className="px-2 text-gray-500 uppercase bg-white">
                  Or continue with
                </span>
              </div>
            </div>
          </div>
          <OAuthSignIn setError={setError} />
          <button
            onClick={() => handlePasskeySignIn()}
            className="text-sm font-medium underline underline-offset-2"
          >
            Use passkey instead
          </button>

          <div className="col-span-1 "></div>
        </>
      )}
      {isVerificationTrue && (
        <>
          <div className="flex flex-col w-full gap-2 ">
            <h1 className=" font-semibold text-[1.5rem]">Check your email </h1>
            <p className=" text-[#475467] text-[0.879rem]">
              Use the sign in link sent to your email
            </p>
            <div className="flex items-center justify-center gap-2 ">
              <p className=" text-[#191B33] text-[0.879rem]">
                {form?.getValues("email")}
              </p>
              <button
                className=" hover:cursor-pointer"
                onClick={() => {
                  setIsVerificationTrue(false);
                  navigate(ROUTES.LOGIN);
                }}
              >
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.3335 3.16665H2.66683C2.31321 3.16665 1.97407 3.30713 1.72402 3.55718C1.47397 3.80723 1.3335 4.14637 1.3335 4.49999V13.8333C1.3335 14.1869 1.47397 14.5261 1.72402 14.7761C1.97407 15.0262 2.31321 15.1667 2.66683 15.1667H12.0002C12.3538 15.1667 12.6929 15.0262 12.943 14.7761C13.193 14.5261 13.3335 14.1869 13.3335 13.8333V9.16666M12.3335 2.16665C12.5987 1.90144 12.9584 1.75244 13.3335 1.75244C13.7086 1.75244 14.0683 1.90144 14.3335 2.16665C14.5987 2.43187 14.7477 2.79158 14.7477 3.16665C14.7477 3.54173 14.5987 3.90144 14.3335 4.16665L8.00016 10.5L5.3335 11.1667L6.00016 8.49999L12.3335 2.16665Z"
                    stroke="#09090B"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="flex flex-col justify-center gap-4 mt-4 ">
            <p
              className={` ${
                canResend ? " text-black" : "text-[#5E5F70]"
              } font-medium text-[0.875rem]`}
            >
              Didn’t receive a link?{" "}
              {canResend ? (
                <button
                  className="text-blue-500 cursor-pointer"
                  onClick={(e) => {
                    setError("");
                    handleResend(e);
                  }}
                >
                  Resend
                </button> // Add click functionality as needed
              ) : (
                `Resend (${countdown})`
              )}
            </p>
            {/* horizontal line  */}
            <div className="relative mt-2">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300"></div>
              </div>
              <div className="relative flex justify-center text-[12px]"></div>
            </div>
            <button
              onClick={() => {
                setError("");
                navigate(ROUTES.LOGIN);
                setIsVerificationTrue(false);
              }}
              className=" text-[#191B33] font-medium text-[0.879rem]"
            >
              Use another method
            </button>
          </div>
        </>
      )}
    </CommonLayout>
  );
};

export default LoginContent;
