import { combineReducers, configureStore } from "@reduxjs/toolkit";
import formReducer from "./slices/formSlice.tsx";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
  // Add a blacklist or whitelist to control what gets persisted
  blacklist: [], // Add any reducer names that shouldn't be persisted
  // whitelist: ['forms'], // Alternative: only persist specific reducers
};

const rootReducer = combineReducers({
  forms: formReducer,
});

const persistedRootReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedRootReducer,
  // Add middleware configuration to handle non-serializable values
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"],
        // Ignore these field paths in all actions
        ignoredActionPaths: ["register"],
        // Ignore these paths in the state
        ignoredPaths: ["forms.someNonSerializableField"],
      },
    }),
});

export const persistor = persistStore(store);
