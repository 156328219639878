import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import { Tooltip } from "antd";
import { Button } from "../ui/button.tsx";
import { Flex, Space } from "antd";
import { Grid } from "antd";
import { useFormContext } from "../../Contexts/FormContext";
import logoWithText from "../../assets/images/logoWithText.png";
import { useFormEditorContext } from "../../Contexts/FormEditorContext";

const { useBreakpoint } = Grid;

export const FormFooter = ({ showControls }) => {
  const { nextButtonPress, previous, formTheme } = useFormContext();
  const { theme } = useFormEditorContext();
  //console.log("theme : ", theme);
  const isFormEditor = location.pathname.includes("form-editor");
  const constTheme = isFormEditor ? theme : formTheme;

  return (
    <FormFooterFormat
      next={nextButtonPress}
      constTheme={constTheme}
      previous={previous}
      showControls={showControls}
    />
  );
  //   <Typography.Text>test footer</Typography.Text>;
};

export const FormFooterFormat = ({
  next = () => {},
  previous = () => {},
  showControls = true,
  constTheme,
  theme,
}) => {
  const { md: s } = useBreakpoint();

  return (
    <div className="relative w-full py-4">
      {/* Logo Section */}
      <div className="absolute bottom-4 left-4">
        <img src={logoWithText} className="h-6 w-[108.75px]" alt="Logo" />
      </div>

      {/* Controls Section */}
      {showControls && (
        <div className="absolute flex gap-1 bottom-4 right-4">
          {/* Back Button */}
          <Button
            icon="left"
            variant="ghost"
            className=" w-9 h-9 p-0 flex items-center justify-center"
            onClick={previous}
            style={{
              backgroundColor: constTheme?.button_color || theme?.button_color,
              color: constTheme?.button_text_color || theme?.button_text_color,
            }}
          >
            <svg
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 12.3496L6 8.34961L10 4.34961"
                stroke={
                  constTheme?.button_text_color || theme?.button_text_color
                }
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Button>

          {/* Next Button */}
          <Button
            icon="right"
            variant="ghost"
            className=" w-9 h-9 p-0 flex items-center justify-center "
            style={{
              backgroundColor: constTheme?.button_color || theme?.button_color,
              color: constTheme?.button_text_color || theme?.button_text_color,
            }}
            onClick={next}
          >
            <svg
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 12.3496L10 8.34961L6 4.34961"
                stroke={
                  constTheme?.button_text_color || theme?.button_text_color
                }
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Button>
        </div>
      )}
    </div>
  );
};

const prevFooter = () => {
  return (
    <Flex
      className="fill-available"
      style={{
        margin: "1%",
        marginRight: "1%",
        justifyContent: s ? "flex-end" : "space-evenly",
      }}
      gap={"large"}
    >
      <Flex style={{ alignItems: "center" }}>
        <Typography.Text
          style={{ fontWeight: "normal", color: constTheme?.text_color }}
        >
          {/* Crafted with */}
          Powered by
          {/* <Typography.Text strong>BuildForm</Typography.Text> */}
        </Typography.Text>
        <img
          src={logoWithText}
          style={{ height: "1.5em", margin: "0 0.5em" }}
        />
      </Flex>
      {showControls && (
        <Space.Compact>
          <Tooltip title="Back">
            <Button
              style={{
                backgroundColor:
                  constTheme?.button_color || theme?.button_color,
                color:
                  constTheme?.button_text_color || theme?.button_text_color,
              }}
              // type="primary"
              icon={<ArrowUpOutlined />}
              onClick={previous}
            />
          </Tooltip>
          <Tooltip title="Next">
            <Button
              style={{
                backgroundColor:
                  constTheme?.button_color || theme?.button_color,
                color:
                  constTheme?.button_text_color || theme?.button_text_color,
              }}
              // type="primary"
              icon={<ArrowDownOutlined />}
              onClick={next}
            />
          </Tooltip>
        </Space.Compact>
      )}
    </Flex>
  );
};
