export const MaxHeight = ({ children, editor }) => {
  return (
    <div
      style={{
        maxHeight: "100%",
        display: "flex",
        flexDirection: "column",
        flex: 1,
        height: editor ? "95vh" : "100vh",
        overflow: "hidden",
      }}
    >
      {children}
    </div>
  );
};
