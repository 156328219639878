import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ClerkProvider } from "@clerk/clerk-react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const NODE_ENV = process.env.REACT_APP_ENVIRONMENT;
let PUBLISHABLE_KEY;

if (NODE_ENV === "production")
  PUBLISHABLE_KEY = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY_PROD;
else PUBLISHABLE_KEY = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

const signInFallbackRedirectUrl =
  process.env.REACT_APP_CLERK_SIGN_IN_FORCE_REDIRECT_URL;
const signUpFallbackRedirectUrl =
  process.env.REACT_APP_CLERK_SIGN_UP_FORCE_REDIRECT_URL;

if (!PUBLISHABLE_KEY) {
  console.log("Missing Publishable key");
}

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ClerkProvider
      publishableKey={PUBLISHABLE_KEY}
      signInFallbackRedirectUrl={signInFallbackRedirectUrl}
      signUpForceRedirectUrl={signUpFallbackRedirectUrl}
    >
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </ClerkProvider>
  </React.StrictMode>
);

reportWebVitals();
