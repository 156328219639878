import React, { useState } from "react";
import Center from "../Basic/Center";
import { MaxHeight } from "../Basic/MaxHeight";
import { Flex } from "antd";
import browserSignature from "browser-signature";
import { useEffect } from "react";
import { BarLoader } from "react-spinners";
import { useFormContext } from "../../Contexts/FormContext";
import { useApi } from "../../Api/useApi.ts";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectForms } from "../../Redux/selectors.jsx";
import { convertFormObject } from "../MyEditor/FormObjectConfig.jsx";
import { FRONTEND_VERSION } from "../../Constants/index.js";
import { Image } from "antd";
import LogoWithText from "../../assets/images/logoWithText.png";

export const LoadingScreen = () => {
  const {
    setLoading,
    isLoading,
    setData,
    getDataFromLocal,
    setCurrentQuestionIndex,
    setIsInitialPage,
    setSignature,
    next,
    setResponseId,
    setAcceptResponses,
    setFormNotFound,
  } = useFormContext();
  const api = useApi();
  const [metadata, setMetadata] = useState({
    user_agent: navigator.userAgent,
    platform: navigator.platform,
    referer: document.referrer,
    ip: "",
    browser: navigator.appName,
    client: navigator.appVersion,
    source: document.referrer,
    medium: document.referrer,
    domain_type: document.referrer,
    utm_id: document.referrer,
    utm_source: document.referrer,
    utm_medium: document.referrer,
    utm_campaign: document.referrer,
    utm_term: document.referrer,
    utm_content: document.referrer,
  });

  //let { urlId: formUrlId } = useParams();
  let { urlId: formUrlId } = useParams();
  const forms = useSelector(selectForms);
  const { id } = useParams();

  const onNext = (data) => {
    const signature = browserSignature();
    setSignature(signature);
    api.responses
      .startResponse({
        form_id: data?.id,
        signature,
        frontend_version: FRONTEND_VERSION,
        landed_at: new Date(),
        updated_at: new Date(),
        metadata: metadata,
      })
      .then((res) => {
        console.log("response", { res });
        setResponseId(res?.data?.id);
        next();
      });
  };

  useEffect(() => {
    if (!getDataFromLocal) {
      const form = forms.find((form) => form.id === id);
      const isPublished = form?.url_id ? true : false;

      if (formUrlId) {
        api.forms
          .fetchFormData(formUrlId)
          .then((res) => {
            console.log("res", res?.status);
            if (res?.status === 410) {
              setAcceptResponses(true);
              setLoading(false);
            }
            if (res?.status === 404) {
              setFormNotFound(true);
              setLoading(false);
            }
            if (res?.data?.welcome_screens?.[0]?.title) {
              setData(res?.data);
            } else {
              setIsInitialPage(false);
              setCurrentQuestionIndex(0);
              setData(res?.data);
              onNext(res?.data);
            }
          })
          .catch((err) => {
            console.error("Error fetching form data:", err);
          })
          .finally(() => setLoading(false));
      } else if (isPublished) {
        api.forms
          .fetchFormData(form?.url_id)
          .then((res) => {
            setData(res.data);
          })
          .catch((err) => {
            console.error("Error fetching published form data:", err);
          })
          .finally(() => setLoading(false));
      } else {
        const newObj = convertFormObject(form);
        setData(newObj);
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, [formUrlId]);

  console.log("isLoading", isLoading);

  return (
    <MaxHeight>
      {/* <FormHeader /> */}
      <Center>
        <Flex vertical gap={10}>
          <Image
            preview={false}
            src={LogoWithText}
            // height={30}
            width={"10vw"}
          />
          <div
            style={{
              opacity: isLoading ? 1 : 0,
              width: "10vw",
              //   backgroundColor: "red",
              borderRadius: "5px",
              overflow: "hidden",
            }}
          >
            <BarLoader color={"rgb(30, 47, 105)"} width={"10vw"} />
          </div>
        </Flex>
      </Center>
    </MaxHeight>
  );
};
