import React, { useState } from "react";

import { useSearchParams } from "react-router-dom";

import {
  ChevronDownIcon,
  Download,
  FilterIcon,
  SearchIcon,
  X,
} from "lucide-react";

import { Table } from "@tanstack/react-table";

import { Button } from "../ui/button.tsx";
import { Input } from "../ui/input.tsx";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../Components/ui/popover.tsx";
import {
  ToggleGroup,
  ToggleGroupItem,
} from "../../Components/ui/toggle-group.tsx";
import { DataTableFacetedFilter } from "./DataTableFilters.tsx";

import { DateRangePicker } from "../ui/date-range-picker.tsx";

import { format } from "date-fns";

import { cn } from "../../lib/utils.js";

interface DataTableToolbarProps<TData> {
  table: Table<TData>;
}

const Statuses = [
  {
    value: "completed",
    label: "Completed",
  },
  {
    value: "partial",
    label: "Partial",
  },
  {
    value: "started",
    label: "Started",
  },
];

export function DataTableToolbar<TData>({
  table,
  className,
}: DataTableToolbarProps<TData> & React.HTMLAttributes<HTMLDivElement>) {
  const [searchParams, setSearchParams] = useSearchParams();
  const isFiltered = table.getState().columnFilters.length > 0;
  const [openFilter, setOpenFilter] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<{
    value: string;
    label: string;
  }>({ value: "all", label: "All Responses" });

  const query = searchParams.get("query") ?? "";
  const from = searchParams.get("from");
  const to = searchParams.get("to");
  const type = searchParams.get("type");

  return (
    <div className={cn("flex items-center justify-between", className)}>
      <div className="flex flex-1 items-center space-x-2">
        <div className="relative max-w-[275px]">
          <div className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground">
            <SearchIcon className="h-4 w-4" />
          </div>
          <Input
            placeholder="Search submissions..."
            value={query}
            onChange={(event) => {
              setSearchParams({ query: event.target.value });
            }}
            className="w-full h-[36px] pl-8 focus-visible:ring-offset-0 focus-visible:outline-none focus-visible:ring-0 text-xs"
          />
        </div>

        {/* {table.getColumn("Status") && (
          <DataTableFacetedFilter
            column={table.getColumn("Status")}
            title="Status"
            options={Statuses}
          />
        )}
        {isFiltered && (
          <Button
            variant="ghost"
            onClick={() => table.resetColumnFilters()}
            className="h-8 px-2 lg:px-3"
          >
            Reset
            <X className="ml-2 h-4 w-4" />
          </Button>
        )} */}
      </div>
      <div className="flex flex-row items-center gap-2">
        <DateRangePicker
          date={{
            from: from ? new Date(from) : undefined,
            to: to ? new Date(to) : undefined,
          }}
          setDate={(date) => {
            if (!date) return;
            setSearchParams({
              // @ts-ignore
              from: date.from ? format(date.from, "yyyy-MM-dd") : "",
              // @ts-ignore
              to: date.to ? format(date.to, "yyyy-MM-dd") : "",
            });
          }}
          className="text-xs"
        />
        <Popover onOpenChange={(value) => setOpenFilter(value)}>
          <PopoverTrigger asChild>
            <Button
              variant="outline"
              onClick={() => setOpenFilter(!openFilter)}
              className={`rounded-[8px] h-[36px] text-xs ${
                openFilter
                  ? "text-white bg-[#191B33] hover:bg-[#191B33] hover:text-white"
                  : "text-black bg-white"
              }`}
            >
              {selectedFilter.label}
              <ChevronDownIcon className="w-4 h-4 ml-2" />
            </Button>
          </PopoverTrigger>
          <PopoverContent align="end" className="bg-[#191B33] text-white">
            <Filter
              value={selectedFilter}
              onValueChange={(v) => {
                setSelectedFilter(v);
                setSearchParams({ type: v.value });
              }}
            />
          </PopoverContent>
        </Popover>
      </div>
    </div>
  );
}

type FilterProps = {
  value: { value: string; label: string };
  onValueChange: ({ value, label }: { value: string; label: string }) => void;
};

function Filter({ value, onValueChange }: FilterProps) {
  const handleOnValueChange = (value: string) => {
    if (value === "all")
      onValueChange({ value: "all", label: "All Responses" });
    if (value === "partial")
      onValueChange({ value: "partial", label: "Partial" });
    if (value === "started")
      onValueChange({ value: "started", label: "Started" });
    if (value === "completed")
      onValueChange({ value: "completed", label: "Completed" });
  };

  return (
    <div>
      <div className="flex flex-row items-center gap-2 text-sm">
        <FilterIcon className="w-4 h-4" />
        Response Filter
      </div>
      <ToggleGroup
        type="single"
        defaultValue="all"
        value={value.value || "all"}
        onValueChange={handleOnValueChange}
        className="flex flex-col gap-1 w-full text-left mt-4"
      >
        <ToggleGroupItem
          value="all"
          className="w-full h-[32px] bg-[#172B4D] border border-[#44546F] rounded-[4px] text-left flex justify-start text-xs"
        >
          All Responses
        </ToggleGroupItem>
        <ToggleGroupItem
          value="started"
          className="w-full h-[32px] bg-[#172B4D] border border-[#44546F] rounded-[4px] flex justify-start text-xs"
        >
          Started
        </ToggleGroupItem>
        <ToggleGroupItem
          value="partial"
          className="w-full h-[32px] bg-[#172B4D] border border-[#44546F] rounded-[4px] flex justify-start text-xs"
        >
          Partial
        </ToggleGroupItem>
        <ToggleGroupItem
          value="completed"
          className="w-full h-[32px] bg-[#172B4D] border border-[#44546F] rounded-[4px] flex justify-start text-xs"
        >
          Completed
        </ToggleGroupItem>
      </ToggleGroup>
    </div>
  );
}
