import React from "react";
import { Tooltip } from "antd";
import {
  FIELD_TYPE_OPTIONS,
  QUESTION_ICONS,
  FIELD_TYPE_GROUPS,
  QUESTION_TYPES,
} from "../../Constants/questionTypes";
import { useFormEditorContext } from "../../Contexts/FormEditorContext";
import { randomString } from "../../Utils/string";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../Components/ui/dialog.tsx";
import { Separator } from "../../Components/ui/separator.tsx";

const buttonStyles =
  "flex items-center justify-start py-[7px] w-full  pl-2 bg-white rounded-md gap-2";

export const AddFieldButton = ({ ai }) => {
  const [searchQuery, setSearchQuery] = React.useState("");

  const {
    addField,
    setSelectedField,
    setWelcomeScreen,
    isWelcomeIsEnabled,
    setIsWelcomeIsEnabled,
    isEndIsEnabled,
    setIsEndIsEnabled,
    setEndScreen,
  } = useFormEditorContext();

  function removeUnderscore(str) {
    if (str === "yes_no") return "Yes/No";
    return str.replace(/_/g, " ");
  }

  const filterFields = (group, key) => {
    const label = FIELD_TYPE_OPTIONS[key]?.label || removeUnderscore(key);
    return label.toLowerCase().includes(searchQuery.toLowerCase());
  };

  const getGroupBackgroundColor = (group) => {
    return "bg-gray-100";
    // switch (group) {
    //   case "AI Features":
    //     return "bg-[#EEF4FF]";
    //   case "Choices":
    //     return "bg-[#ECFDF3]";
    //   case "Contact Info":
    //     return "bg-[#F9F5FF]";
    //   case "Text and Video":
    //     return "bg-[#FFFAEB]";
    //   case "Welcome and End Screens":
    //     return "bg-[#F1F2F4]";
    //   default:
    //     return "bg-[#FEF2F2]";
    // }
  };

  const addWelcomeScreen = () => {
    setWelcomeScreen({
      title: "Welcome to the form",
      description: "This is a description",
      button_text: "Start",
      layout: {
        type: "float",
        attachment: {
          type: "image",
          href: "",
        },
        placement: "right",
      },
    });
    setIsWelcomeIsEnabled(true);
    setSelectedField("welcome-screen");
  };

  const addEndScreen = () => {
    setEndScreen({
      title: "Thank you",
      type: "url_redirect",
      description: "This is a description of the form end",
    });
    setIsEndIsEnabled(true);
    setSelectedField("end-screen");
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <button className="inline-flex items-center justify-center whitespace-nowrap font-medium ring-offset-white transition focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-zinc-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-[#DCDFE4] bg-white  hover:text-zinc-900 w-[90px]  rounded-md text-xs h-7 px-2.5 gap-[3px]">
          <svg
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.00033 3.76562V11.9323M2.91699 7.84896H11.0837"
              stroke="#191B33"
              stroke-width="0.875"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          {ai ? "Add step" : "Add Step"}
        </button>
      </DialogTrigger>

      <DialogContent className="sm:max-w-[870px] p-3 top-[50%]">
        <DialogHeader>
          <DialogTitle className="flex items-center gap-1 text-sm font-medium ">
            <svg
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.05566 1.84766H3.139C2.81683 1.84766 2.55566 2.10882 2.55566 2.43099V6.51432C2.55566 6.83649 2.81683 7.09766 3.139 7.09766H6.05566C6.37783 7.09766 6.639 6.83649 6.639 6.51432V2.43099C6.639 2.10882 6.37783 1.84766 6.05566 1.84766Z"
                stroke="#09090B"
                stroke-width="0.875"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12.4723 1.84766H9.55566C9.2335 1.84766 8.97233 2.10882 8.97233 2.43099V4.18099C8.97233 4.50316 9.2335 4.76432 9.55566 4.76432H12.4723C12.7945 4.76432 13.0557 4.50316 13.0557 4.18099V2.43099C13.0557 2.10882 12.7945 1.84766 12.4723 1.84766Z"
                stroke="#09090B"
                stroke-width="0.875"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12.4723 7.09766H9.55566C9.2335 7.09766 8.97233 7.35882 8.97233 7.68099V11.7643C8.97233 12.0865 9.2335 12.3477 9.55566 12.3477H12.4723C12.7945 12.3477 13.0557 12.0865 13.0557 11.7643V7.68099C13.0557 7.35882 12.7945 7.09766 12.4723 7.09766Z"
                stroke="#09090B"
                stroke-width="0.875"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M6.05566 9.43099H3.139C2.81683 9.43099 2.55566 9.69216 2.55566 10.0143V11.7643C2.55566 12.0865 2.81683 12.3477 3.139 12.3477H6.05566C6.37783 12.3477 6.639 12.0865 6.639 11.7643V10.0143C6.639 9.69216 6.37783 9.43099 6.05566 9.43099Z"
                stroke="#09090B"
                stroke-width="0.875"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <span>Add New Elements</span>
          </DialogTitle>
          <DialogDescription>
            Customize your form with fields, features, and integrations.
          </DialogDescription>
        </DialogHeader>
        <div className="flex flex-col w-full gap-2 ">
          <Separator className="absolute left-0 w-full " />
          <div className="gap-2 mt-4 ">
            <div className="  bg-[#F7F8F9] p-3 rounded-lg">
              <span className="flex items-center gap-1 text-xs font-medium ">
                Recommended{" "}
              </span>
              <div className="grid grid-cols-4 gap-1 mt-2 ">
                {FIELD_TYPE_GROUPS.Recommended.map((key) => (
                  <DialogClose
                    key={key}
                    className={`${buttonStyles}`}
                    onClick={() => {
                      const field = {
                        id: randomString(10),
                        ...FIELD_TYPE_OPTIONS[key],
                      };
                      addField(field);
                      setSelectedField(field);
                    }}
                    // disabled={!FIELD_TYPE_OPTIONS[key]?.label}
                  >
                    <span
                      className={`text-[14px] flex items-center justify-center w-[22px] h-[22px] rounded-[0.4rem]  bg-gray-100  `}
                    >
                      {QUESTION_ICONS[key]}
                    </span>
                    <span className="text-xs capitalize ">
                      {FIELD_TYPE_OPTIONS[key]?.label
                        ? FIELD_TYPE_OPTIONS[key]?.label
                        : removeUnderscore(key)}
                    </span>
                    {FIELD_TYPE_OPTIONS[key].type ===
                      QUESTION_TYPES.AI_STATEMENT && <BetaBadge />}
                  </DialogClose>
                ))}
              </div>
            </div>
          </div>

          <div className="grid w-full grid-cols-4 gap-2 ">
            {Object.keys(FIELD_TYPE_GROUPS)
              ?.filter(
                (group) =>
                  group !== "Recommended" &&
                  group !== "Welcome and End Screens" &&
                  group !== "Embeddings and Uploads"
              )
              ?.map((group) => {
                const filteredFields = FIELD_TYPE_GROUPS[group].filter((key) =>
                  filterFields(group, key)
                );
                if (filteredFields.length === 0 && searchQuery) return null;
                return (
                  <div key={group} className="  bg-[#F7F8F9] p-3 rounded-lg">
                    {group === "AI Features" ? (
                      <h3 className="flex items-center gap-1 mb-2 text-xs font-medium ">
                        <span>AI Features</span>
                        <svg
                          width="14"
                          height="15"
                          viewBox="0 0 14 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.91667 2.25V4.58333M11.0833 10.4167V12.75M1.75 3.41667H4.08333M9.91667 11.5833H12.25M7 2.25L5.88467 5.64092C5.82759 5.81446 5.73055 5.97218 5.60136 6.10136C5.47218 6.23055 5.31446 6.32759 5.14092 6.38467L1.75 7.5L5.14092 8.61533C5.31446 8.67241 5.47218 8.76945 5.60136 8.89864C5.73055 9.02782 5.82759 9.18554 5.88467 9.35908L7 12.75L8.11533 9.35908C8.17241 9.18554 8.26945 9.02782 8.39864 8.89864C8.52782 8.76945 8.68554 8.67241 8.85908 8.61533L12.25 7.5L8.85908 6.38467C8.68554 6.32759 8.52782 6.23055 8.39864 6.10136C8.26945 5.97218 8.17241 5.81446 8.11533 5.64092L7 2.25Z"
                            stroke="#191B33"
                            stroke-width="0.875"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </h3>
                    ) : (
                      <h3 className="flex items-center gap-1 mb-2 text-xs font-medium ">
                        {group}
                      </h3>
                    )}
                    {filteredFields.map((key) =>
                      FIELD_TYPE_OPTIONS[key]?.label ? (
                        <DialogClose
                          key={key}
                          className={`${buttonStyles} mb-1`}
                          onClick={() => {
                            const field = {
                              id: Math.random().toString(36).substr(2, 10),
                              ...FIELD_TYPE_OPTIONS[key],
                            };
                            addField(field);
                            setSelectedField(field);
                          }}
                        >
                          <span
                            className={`text-[14px]  flex items-center justify-center w-[22px] h-[22px] rounded-[0.4rem] ${getGroupBackgroundColor(
                              group
                            )}`}
                          >
                            {QUESTION_ICONS[key]}
                          </span>
                          <span className="text-xs ">
                            {FIELD_TYPE_OPTIONS[key].label === "Section"
                              ? "Statement"
                              : FIELD_TYPE_OPTIONS[key].label}
                          </span>
                          {FIELD_TYPE_OPTIONS[key].type ===
                            QUESTION_TYPES.AI_STATEMENT && <BetaBadge />}
                        </DialogClose>
                      ) : (
                        <Tooltip
                          key={key}
                          placement="top"
                          title={
                            <span className="text-xs break-normal whitespace-pre-line ">
                              Coming soon! You’ll be the first {"\n"} to know
                              (probably)
                            </span>
                          }
                        >
                          <DialogClose
                            className={`${buttonStyles} mb-1`}
                            disabled
                          >
                            <span
                              className={`text-[14px] flex items-center justify-center w-[22px] h-[22px] rounded-[0.4rem] ${getGroupBackgroundColor(
                                group
                              )}`}
                            >
                              {QUESTION_ICONS[key]}
                            </span>
                            <span className="text-[#bdbdbd] text-xs capitalize">
                              {removeUnderscore(key)}
                            </span>
                          </DialogClose>
                        </Tooltip>
                      )
                    )}
                  </div>
                );
              })}
          </div>
          <div className="grid w-full grid-cols-2 gap-2 ">
            {Object.keys(FIELD_TYPE_GROUPS)
              ?.filter(
                (group) =>
                  group === "Welcome and End Screens" ||
                  group === "Embeddings and Uploads"
              )
              ?.map((group) => {
                const filteredFields = FIELD_TYPE_GROUPS[group].filter((key) =>
                  filterFields(group, key)
                );
                if (filteredFields.length === 0 && searchQuery) return null;
                return (
                  <div key={group} className="  bg-[#F7F8F9] p-3 rounded-lg">
                    <h3 className="flex items-center gap-1 text-xs font-medium ">
                      {group}
                    </h3>
                    <div className="grid grid-cols-2 mt-2 gap-x-1 ">
                      {group === "Welcome and End Screens" && (
                        <Tooltip
                          key="welcome-tooltip"
                          placement="top"
                          title={
                            isWelcomeIsEnabled ? (
                              <span className="text-xs text-center font-medium text-[#FFF] px-2 py-1 bg-[#1A202C] rounded-md shadow-lg">
                                You can only use only one time.
                              </span>
                            ) : null
                          }
                        >
                          <DialogClose
                            className={`${buttonStyles} mb-1`}
                            onClick={addWelcomeScreen}
                            disabled={isWelcomeIsEnabled}
                          >
                            <span
                              className={`text-[14px] w-[22px] h-[22px] flex items-center justify-center rounded-[0.4rem] bg-[#F1F2F4]`}
                            >
                              <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M2.9165 1.75L11.0832 7L2.9165 12.25V1.75Z"
                                  stroke="#44546F"
                                  strokeWidth="0.875"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </span>
                            <span className="text-xs ">Welcome Screen</span>
                          </DialogClose>
                        </Tooltip>
                      )}
                      {group === "Welcome and End Screens" && (
                        <Tooltip
                          key="end-tooltip"
                          placement="top"
                          title={
                            isEndIsEnabled ? (
                              <span className="text-xs text-center font-medium text-[#FFF] px-2 py-1 bg-[#1A202C] rounded-md shadow-lg">
                                You can only use only one time.
                              </span>
                            ) : null
                          }
                        >
                          <DialogClose
                            className={`${buttonStyles} mb-1`}
                            onClick={addEndScreen}
                            disabled={isEndIsEnabled}
                          >
                            <span
                              className={`text-[14px] w-[22px] h-[22px] flex items-center justify-center rounded-[0.4rem] bg-[#F1F2F4]`}
                            >
                              <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M11.6375 2.80052V2.56698C11.6748 2.63657 11.6959 2.71608 11.6959 2.80052V11.2005C11.6959 11.285 11.6748 11.3645 11.6375 11.4341V11.2005V2.80052ZM11.2 2.30469C11.2845 2.30469 11.364 2.32579 11.4336 2.36302H11.2H2.80003H2.5665C2.63609 2.32579 2.7156 2.30469 2.80003 2.30469H11.2ZM2.36253 2.56698V2.80052V11.2005V11.4341C2.32531 11.3645 2.3042 11.285 2.3042 11.2005V2.80052C2.3042 2.71608 2.32531 2.63657 2.36253 2.56698ZM2.80003 11.6964C2.71559 11.6964 2.63608 11.6752 2.56649 11.638H2.80003H11.2H11.4336C11.364 11.6752 11.2845 11.6964 11.2 11.6964H2.80003Z"
                                  fill="#44546F"
                                  stroke="#44546F"
                                  strokeWidth="0.875"
                                />
                              </svg>
                            </span>
                            <span className="text-xs ">End Screen</span>
                          </DialogClose>
                        </Tooltip>
                      )}

                      {filteredFields.map((key) =>
                        FIELD_TYPE_OPTIONS[key]?.label ? (
                          <DialogClose
                            key={key}
                            className={`${buttonStyles} mb-1`}
                            onClick={() => {
                              const field = {
                                id: Math.random().toString(36).substr(2, 10),
                                ...FIELD_TYPE_OPTIONS[key],
                              };
                              addField(field);
                              setSelectedField(field);
                            }}
                          >
                            <span
                              className={`text-[14px] w-[22px] h-[22px] flex items-center justify-center rounded-[0.4rem] ${getGroupBackgroundColor(
                                group
                              )}`}
                            >
                              {QUESTION_ICONS[key]}
                            </span>
                            <span className="text-xs ">
                              {FIELD_TYPE_OPTIONS[key].label === "Statement"
                                ? "Statement"
                                : FIELD_TYPE_OPTIONS[key].label}
                            </span>
                          </DialogClose>
                        ) : (
                          <Tooltip
                            key={key}
                            placement="top"
                            title={
                              <span className="text-xs break-normal whitespace-pre-line ">
                                Coming soon! You’ll be the first {"\n"} to know
                                (probably)
                              </span>
                            }
                          >
                            <DialogClose
                              className={`${buttonStyles} mb-1`}
                              disabled
                            >
                              <span
                                className={`text-[14px] flex items-center justify-center w-[22px] h-[22px] rounded-[0.4rem] ${
                                  key === "calendly"
                                    ? "bg-[#EAF1FE]"
                                    : getGroupBackgroundColor(group)
                                }`}
                              >
                                {QUESTION_ICONS[key]}
                              </span>
                              <span className="text-[#bdbdbd] text-xs capitalize">
                                {removeUnderscore(key)}
                              </span>
                            </DialogClose>
                          </Tooltip>
                        )
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

{
  /* <Input
              placeholder="Search for a field"
              style={{
                padding: "0.2rem",
                justifyContent: "center",
              }}
              className="items-center w-3/4 p-2 my-2 bg-gray-100 border border-gray-200 rounded-lg"
              prefix={
                <SearchOutlined className="pl-2.5 pr-1 text-gray-500 "></SearchOutlined>
              }
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            /> */
}

function BetaBadge() {
  return (
    <div className="bg-[#D4FD04] px-[8px] py-[1px] rounded-full">
      <p className="text-[10px]">beta</p>
    </div>
  );
}
