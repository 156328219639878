import { motion, useAnimate, stagger } from "framer-motion";
import { TITLE_ANIMATION_VALUES } from "../../Constants/textAnimationValues";
import { useEffect } from "react";
import { TEXT_FADE_STAGGER_DURATION } from "../../Constants/animations";
import { useFormContext } from "../../Contexts/FormContext";
import { useFormEditorContext } from "../../Contexts/FormEditorContext";
import { getDynamicTextTitle } from "../../Constants/styles";

export const LiveFadeAnimatedText = ({ textArray }) => {
  const { formTheme } = useFormContext();
  return (
    <motion.div style={{ textAlign: "left" }} layout="position">
      {textArray.map((word, idx) => {
        return (
          <motion.span
            key={word + idx}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={TITLE_ANIMATION_VALUES}
            style={{
              color: formTheme?.text_color,
              fontSize: getDynamicTextTitle(formTheme?.text_size),
            }}
          >
            {word}
          </motion.span>
        );
      })}
    </motion.div>
  );
};

export const FadeAnimatedText = ({
  textArray,
  delay = 0,
  disableAnimation = true,
  editor = false,
}) => {
  //console.log("editor editor", editor);
  // console.log("textArray", textArray);
  const [scope, animate] = useAnimate();

  useEffect(() => {
    if (!disableAnimation) {
      animate(
        "span",
        {
          opacity: 1,
        },
        {
          duration: 1.5,
          delay: stagger(TEXT_FADE_STAGGER_DURATION, { startDelay: delay }),
        }
      );
    }
  }, [scope.current]);

  const { formTheme } = useFormContext();
  const { theme } = useFormEditorContext();

  const isFormEditor = location.pathname.includes("form-editor");
  const constTheme = isFormEditor ? theme : formTheme;

  return (
    <motion.div
      style={{ textAlign: "left", color: constTheme?.text_color }}
      ref={scope}
    >
      {textArray.map((word, idx) => {
        return (
          <motion.span
            key={word + idx}
            style={{ opacity: disableAnimation || editor ? 1 : 0 }}
            //   className="text-black opacity-0 dark:text-white"
          >
            {word}{" "}
          </motion.span>
        );
      })}
    </motion.div>
  );
};
