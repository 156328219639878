import { createSlice } from "@reduxjs/toolkit";
import { QUESTION_TYPES } from "../../Constants/questionTypes";
// import type { PayloadAction } from "@reduxjs/toolkit";

interface IField {
  id: string;
  type: typeof QUESTION_TYPES.SHORT_TEXT;
  title: string;
  label: string;
  validation: {
    required?: boolean;
  };
}

export interface IForm {
  name: string;
  type: string;
  welcomeScreen: {
    title: string;
    type: string;
    description?: string;
    button_text?: string;
    layout?: {
      type: string;
      attachment?: {
        type: string;
        href?: string;
      };
      placement?: string;
    };
  };
  endScreen: {
    title: string;
    type: string;
    description?: string;
  };
  fields: IField[];
  settings: {
    language: "en";
    progress_bar: "percentage";
    meta: {
      title: "Bootcamp Application Form";
      allow_indexing: true;
      description: null;
      image: {
        link: string | null;
      };
    };
    hide_navigation: false;
    is_public: true;
    is_trial: false;
    show_progress_bar: true;
    are_uploads_public: false;
    show_time_to_complete: false;
    show_number_of_submissions: true;
    show_cookie_consent: false;
    show_question_number: true;
    show_key_hint_on_choices: true;
    autosave_progress: true;
    free_form_navigation: false;
    accept_responses: boolean;
  };
  hidden?: [] | null;
  links?: {
    display: string | null;
    responses: string | null;
    custom: string | null;
  };
  is_ai_form: false;
  ai_form: null;
}

export interface IRevision {
  responses_count: number;
  status: string;
  revision: {
    _id: string;
    form: IForm;
    theme: ITheme;
  };
}

export interface ITheme {
  primary_color: string;
  secondary_color: string;
  text_color: string;
  background_color: string;
  font_family: any;
  name: string;
  button_color: string;
  answer_text_color: string;
  button_size: "small" | "medium" | "large"; // Based on the "large" value provided
  button_text_color: string;
  is_bold: boolean;
  text_size: "small" | "medium" | "large"; // Based on the "medium" value provided
  visibility: "private" | "public"; // Assuming the visibility is a string that represents privacy
  logo: {
    _id: string;
    type: "image"; // Assuming this will always be "image"
    link: string;
    properties: {
      description: string;
      focal_point: {
        x: number;
        y: number;
      };
    };
  };
  rounded_corners: number; // Based on the "large" value provided #TODO: change this to a number
  _id: string;
  created_at: string; // Assuming this is an ISO date string
  updated_at: string; // Assuming this is an ISO date string
  owner_id: string;
}

export interface ICounterState {
  forms: IRevision[];
  responses: any[];
}

const initialState: ICounterState = {
  forms: [],
  responses: [],
};

export const counterSlice = createSlice({
  name: "forms",
  initialState,
  reducers: {
    updateForm: (state, action) => {
      const { id } = action.payload;
      const formIndex = state.forms.findIndex((form) => form.id === id);
      state.forms[formIndex] = action.payload;
    },
    addNewForm: (state, action) => {
      state.forms.push(action.payload);
    },
    removeForm: (state, action) => {
      const { id } = action.payload;
      state.forms = state.forms.filter((form) => form.id !== id);
    },
    setFormList: (state, action) => {
      state.forms = action.payload;
    },
    setFormResponse: (state, action) => {
      state.responses = action.payload;
    },
    removeFormList: (state) => {
      state.forms = [];
    },
  },
});
// In your actions file or directly in the slice if you're using Redux Toolkit slices
export const resetStore = () => ({
  type: "RESET_STORE",
});

export const {
  updateForm,
  addNewForm,
  removeForm,
  setFormList,
  setFormResponse,
  removeFormList,
} = counterSlice.actions;

export default counterSlice.reducer;
