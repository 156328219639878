const generateRandomName = (extension) => {
  const randomString = Math.random().toString(36).substring(2, 15);
  const timestamp = Date.now();
  return `${randomString}_${timestamp}.${extension}`;
};

export const base64ToBlob = (base64, mimeType) => {
  const base64WithoutPrefix = base64.split(",")[1];
  const binaryString = atob(base64WithoutPrefix);
  const byteNumbers = new Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    byteNumbers[i] = binaryString.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: mimeType });

  const extension = mimeType.split("/")[1];
  const randomName = generateRandomName(extension);

  return { blob, randomName };
};
