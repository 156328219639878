import React from "react";
import { Flex, Typography } from "antd";
import { motion } from "framer-motion";
import { MaxHeight } from "../Basic/MaxHeight";
import Center from "../Basic/Center";
import { MarkdownTypography } from "./MarkdownTypography";
import { Socials } from "./Socails";
import { FormNextButton } from "./FormNextButton";
import {
  questionDescriptionStyle,
  specialScreenDescriptionStyles,
  specialScreenTitleStyles,
} from "../../Constants/styles";
import { useFormContext } from "../../Contexts/FormContext";
import { useFormEditorContext } from "../../Contexts/FormEditorContext";
import { ROUTES } from "../../Constants/routes";

export const EndScreen = () => {
  questionDescriptionStyle;
  const { data, formTheme } = useFormContext();
  const { theme, endScreen } = useFormEditorContext();
  const isFormEditor = location.pathname.includes("form-editor");
  const constTheme = isFormEditor ? theme : formTheme;
  const end_screen = isFormEditor ? endScreen : data?.thankyou_screens?.[0];

  const additionalStyles = {
    color: constTheme?.text_color,
  };
  return (
    <MaxHeight>
      {/*   <FormHeader />     */}
      <Center>
        <EndScreenContainer
          title={
            <Typography.Text
              style={{ ...specialScreenTitleStyles, ...additionalStyles }}
            >
              {end_screen?.title}
            </Typography.Text>
          }
          description={
            <Typography.Text
              style={{
                ...specialScreenDescriptionStyles,
                ...additionalStyles,
              }}
            >
              <MarkdownTypography>
                {end_screen?.properties?.description}
              </MarkdownTypography>
            </Typography.Text>
          }
          button={
            <FormNextButton isEndScreen={true}>
              Build your own Buildform
            </FormNextButton>
          }
          socials={<Socials />}
        />
      </Center>
    </MaxHeight>
  );
};

export const EndScreenContainer = ({ title, description, button, socials }) => {
  return (
    <Flex
      style={{ width: "50%", alignItems: "start" }}
      vertical
      component={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      gap={8}
    >
      <motion.div
        initial={{ opacity: 0, translateY: 40 }}
        animate={{ opacity: 1, translateY: 0 }}
        exit={{ opacity: 0, translateY: 40 }}
        transition={{ delay: 0, duration: 0.5 }}
      >
        {title}
      </motion.div>
      <motion.div
        initial={{ opacity: 0, translateY: 40 }}
        animate={{ opacity: 1, translateY: 0 }}
        transition={{ delay: 0.2, duration: 0.5 }}
      >
        {description}
      </motion.div>
      <Flex gap={20} vertical>
        <motion.div
          initial={{ opacity: 0, translateY: 40 }}
          animate={{ opacity: 1, translateY: 0 }}
          transition={{ delay: 0.4, duration: 0.5 }}
        >
          {socials}
        </motion.div>
        <motion.div
          initial={{ opacity: 0, translateY: 40 }}
          animate={{ opacity: 1, translateY: 0 }}
          transition={{ delay: 0.6, duration: 0.5 }}
        >
          <a href={`${ROUTES.DASHBOARD}`} target="_blank">
            {button}
          </a>
        </motion.div>
      </Flex>
    </Flex>
  );
};
