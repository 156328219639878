/* import { useEffect, useState } from "react";
import axios from "axios";
import { getCountries } from "react-phone-number-input";

const COUNTRY_STORAGE_KEY = "userCountryCode";

export const useCurrentCountry = () => {
  const [countryCode, setCountryCode] = useState(() => {
    // Try to get the country code from localStorage first
    return localStorage.getItem(COUNTRY_STORAGE_KEY) || null;
  });

  const countries = getCountries();

  const fetchData = async () => {
    try {
      const response = await axios
        .get("https://api.buildform.ai/country")
        .catch((e) => {
          console.error(e);
        });

      if (response?.data && countries?.includes(response?.data)) {
        setCountryCode(response?.data);
        localStorage.setItem(COUNTRY_STORAGE_KEY, response?.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  fetchData();

  return { countryCode };
};
 */

import { useEffect, useState } from "react";
import axios from "axios";
import { getCountries } from "react-phone-number-input";

const COUNTRY_STORAGE_KEY = "userCountryCode";

export const useCurrentCountry = () => {
  const [countryCode, setCountryCode] = useState(() => {
    return localStorage.getItem(COUNTRY_STORAGE_KEY) || null;
  });

  const countries = getCountries();

  useEffect(() => {
    const fetchData = async () => {
      if (countryCode && countryCode !== "XX") return;

      try {
        const response = await axios.get("https://api.buildform.ai/country");
        if (
          response?.data &&
          countries?.includes(response?.data) &&
          response.data !== "XX"
        ) {
          setCountryCode(response?.data);
          localStorage.setItem(COUNTRY_STORAGE_KEY, response?.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [countries, countryCode]);


  return { countryCode };
};
