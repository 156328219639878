import React, { useState } from "react";

import { Input } from "../../ui/input.tsx";
import { ResizableTextarea } from "../../ui/resizable-textarea.tsx";
import FormButton from "../FormButton.tsx";

import useStyles from "../../../Hooks/useStyles.ts";

import { useFormEditorContext } from "../../../Contexts/FormEditorContext.jsx";

import { cn } from "../../../lib/utils.js";

function Email() {
  const { getStyles } = useStyles();
  const { editorField, setEditorField, theme, setFields, device } =
    useFormEditorContext();
  const [text, setText] = useState("");

  const updateField = (fieldId, key, subkey, value) => {
    setFields((prevFields) => {
      const updatedFields = prevFields.map((field) => {
        if (field.id === fieldId) {
          if (subkey) {
            return {
              ...field,
              [key]: {
                ...field[key],
                [subkey]: value,
              },
            };
          } else {
            return {
              ...field,
              [key]: value,
            };
          }
        }
        return field;
      });
      return updatedFields;
    });

    setEditorField((prev) => {
      if (subkey) {
        return {
          ...prev,
          [key]: {
            ...prev[key],
            [subkey]: value,
          },
        };
      } else {
        return {
          ...prev,
          [key]: value,
        };
      }
    });
  };

  const getDeviceStyle = () => {
    if (device === "mobile") {
      return "h-[36px]";
    }
    return "h-[40px]";
  };

  const deviceStyle = getDeviceStyle();

  return (
    <div className="flex flex-col w-full max-w-[606px]">
      <ResizableTextarea
        placeholder="Add question title"
        value={editorField?.title || ""}
        onChange={(value) => updateField(editorField?.id, "title", null, value)}
        style={{
          color: theme?.text_color,
          fontSize: getStyles("h2", theme?.text_size, device).fontSize,
          fontWeight: getStyles("h2", theme?.text_size, device).fontWeight,
          fontFamily: `${theme?.font_family?.family}, ${theme?.font_family?.category}`,
        }}
      />
      <ResizableTextarea
        placeholder="Add question description (Optional)"
        value={editorField?.properties?.description || ""}
        onChange={(value) =>
          updateField(editorField?.id, "properties", "description", value)
        }
        style={{
          color: theme?.text_color,
          fontSize: getStyles("h4", theme?.text_size, device).fontSize,
          fontWeight: getStyles("h4", theme?.text_size, device).fontWeight,
          fontFamily: `${theme?.font_family?.family}, ${theme?.font_family?.category}`,
        }}
        className="mt-2"
      />
      <Input
        type="email"
        value={text}
        onChange={(e) => setText(e.target.value)}
        style={{
          color: theme?.answer_text_color,
          fontSize: getStyles("h4", theme?.text_size, device).fontSize,
          fontWeight: getStyles("h4", theme?.text_size, device).fontWeight,
          fontFamily: `${theme?.font_family?.family}, ${theme?.font_family?.category}`,
        }}
        className={cn(
          `p-0 mt-4 border-x-0 border-t-0 rounded-none border-b border-[#B3B9C4] text-left bg-transparent focus-visible:ring-0 focus-visible:ring-offset-0 outline-none overflow-hidden`,
          deviceStyle
        )}
      />
      <FormButton className="mt-3">Next</FormButton>
    </div>
  );
}

export default Email;
