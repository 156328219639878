function getOS() {
  const userAgent = window.navigator.userAgent,
    platform =
      window.navigator?.userAgentData?.platform || window.navigator.platform,
    macosPlatforms = ["macOS", "Macintosh", "MacIntel", "MacPPC", "Mac68K"],
    windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
    iosPlatforms = ["iPhone", "iPad", "iPod"];
  let os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = "Mac OS";
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = "iOS";
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = "Windows";
  } else if (/Android/.test(userAgent)) {
    os = "Android";
  } else if (/Linux/.test(platform)) {
    os = "Linux";
  }

  return os;
}

const getOsMetaKey = () => {
  switch (getOS()) {
    case "Mac OS":
      return "Cmd";
    case "Windows":
      return "Window";
    case "iOS":
      return "";
    case "Android":
      return "";
    default:
      return "Super";
  }
};

/* worked one  */
/* export const getAnswersResponseListFromAnswersObject = (answers, common) => {
  const answersList = Object.keys(answers).map((key) => ({
    id: key,
    value: answers[key],
    ...common,
  }));
 
  return answersList
};
 */

export const getAnswersResponseListFromAnswersObject = (answers, questions) => {
  // Recursive function to find the field by ID
  const findFieldById = (id, fields) => {
    for (let field of fields) {
      if (field.id === id) {
        return field;
      }
      if (field.properties && field.properties.fields) {
        const nestedField = findFieldById(id, field.properties.fields);
        if (nestedField) {
          return nestedField;
        }
      }
    }
    return null;
  };

  // Map answers to their respective fields
  return Object.keys(answers).map((key) => {
    const field = findFieldById(key, questions);
    //console.log("field", field, "questions", questions);

    return {
      id: key,
      value: answers[key],
      field: field || {},
    };
  });
};
