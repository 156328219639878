import React, { useState, useEffect, useRef } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import { Button } from "../ui/button.tsx";
import { message, Input as AntInput } from "antd";
import { Input } from "../ui/input.tsx";
import { motion, AnimatePresence } from "framer-motion";
import { useQuery } from "@tanstack/react-query";
import {
  PlusOutlined,
  MinusOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { useApi } from "../../Api/useApi.ts";
import { useLocation, useNavigate } from "react-router-dom";
import { useUser } from "@clerk/clerk-react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog.tsx";
import { Separator } from "../ui/separator.tsx";
import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarTrigger,
} from "../ui/menubar.tsx";

const Workspaces = ({
  selectedWorkSpace,
  setSelectedWorkSpace,
  isCollapsed,
  setIsCollapsed,
  setWorkSpaces,
}) => {
  const api = useApi();
  const inputRef = useRef(null);
  const { user } = useUser();

  const [workspaceName, setWorkspaceName] = useState("");
  const [workspaceNameUpdate, setWorkspaceNameUpdate] = useState(
    workspaceName || ""
  );

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const location = useLocation();
  const navigate = useNavigate();
  const hasCreatedDefaultRef = useRef(false);

  /* workspace handle */
  /* handle create */
  const [messageApi, contextHolder] = message.useMessage();
  const queryClient = useQueryClient();

  const createMutation = useMutation({
    mutationFn: (data) => api.workspaces.createWorkspace(data),
    onSuccess: (data) => {
      messageApi.success("Workspace created successfully");
      setIsCollapsed(true);
      queryClient.invalidateQueries({ queryKey: ["workspaceList"] });
      setSelectedWorkSpace(data?.data?._id);
    },
    onError: (error) => {
      if (error.response) {
        messageApi.error(` ${error.response.data.detail}`);
      } else {
        messageApi.error(
          "Could not create workspace: An unexpected error occurred"
        );
      }
      console.error("Error details:", error);
    },
  });

  const createWorkspace = () => {
    if (workspaceName.trim() === "") {
      messageApi.error("Workspace name is required!");
      return;
    }
    const workspceObj = {
      name: workspaceName,
    };
    createMutation.mutate(workspceObj);

    setWorkspaceName("");
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      createWorkspace();
    }
  };

  const isMigrated = Object.keys(user?.publicMetadata || {}).includes(
    "isMigrated"
  );

  /* handle onBording */
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const isOnboarding = queryParams.get("onboarding") === "true";

    if (isOnboarding && !hasCreatedDefaultRef.current && !isMigrated) {
      const name = `${user?.firstName}'s workspace`;
      const defaultName = "default workspace";
      const workspaceObj = {
        name: `${user?.firstName ? name : defaultName}`,
      };
      createMutation.mutate(workspaceObj);
      hasCreatedDefaultRef.current = true;
      queryParams.delete("onboarding");
      navigate(`${location.pathname}?${queryParams.toString()}`, {
        replace: true,
      });
    }
  }, [location.search, navigate]);

  /* handle fetch */

  const {
    data: workspaceData,
    isSuccess: isSuccessWorkspace,
    isFetching: isFetchingWorkspaces,
  } = useQuery({
    queryKey: ["workspaceList"],
    queryFn: async () => {
      const response = await api.workspaces.getWorkspaces();
      OnSuccessWorkspaceList(response);
      setWorkSpaces(response?.data?.workspaces);
      sessionStorage.removeItem("selectedWorkSpace");
      return response;
    },
    staleTime: Infinity,
    retryDelay: 1000,
  });

  const OnSuccessWorkspaceList = (data) => {
    if (!selectedWorkSpace) {
      setSelectedWorkSpace(data?.data?.workspaces[0]?._id);
    }
  };

  /* handle update */
  const updateMutation = useMutation({
    mutationFn: (data) =>
      api.workspaces.updateWorkspace(selectedWorkSpace, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["workspaceList"] });
      setIsModalOpen(false);
      setSelectedWorkSpace(selectedWorkSpace);
      messageApi.success("Workspace updated successfully");
    },
    onError: (error) => {
      messageApi.error("Could not update workspace");
    },
  });

  const updateWorkspace = () => {
    const workspceObj = {
      name: workspaceNameUpdate,
    };
    updateMutation.mutate(workspceObj);
  };

  /* handleDelete */
  const deleteMutation = useMutation({
    mutationFn: (workspace_id) => api.workspaces.deleteWorkspace(workspace_id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["workspaceList"] });
      sessionStorage.removeItem("selectedWorkSpace");
      setSelectedWorkSpace(
        workspaceData?.data
          ? workspaceData?.data?.workspaces[0]?._id
            ? workspaceData?.data?.workspaces[0]?._id
            : workspaceData?.data?.workspaces[1]?._id
          : ""
      );

      messageApi.success("Workspace deleted successfully");
    },
    onError: (error) => {
      console.log("error", error);
      messageApi.error("Could not delete workspace");
    },
  });

  /* modal states */
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const deleteWorkspace = (workspace_id) => {
    sessionStorage.removeItem("selectedWorkSpace");
    setSelectedWorkSpace("");
    deleteMutation.mutate(workspace_id);
    queryClient.invalidateQueries({ queryKey: ["formList"] });
    setIsDeleteModalOpen(false);
  };

  const handleworkspaceId = (id) => {
    setSelectedWorkSpace(id);
  };

  useEffect(() => {
    // Focus the input when the modal opens
    if (!isCollapsed && inputRef.current) {
      // Use a small timeout to ensure the input is fully rendered
      const timer = setTimeout(() => {
        inputRef.current.focus();
      }, 100);

      // Clean up the timer
      return () => clearTimeout(timer);
    }
  }, [isCollapsed]);

  return (
    <>
      <ul className="flex flex-col pb-4 mt-6">
        {contextHolder}
        <div className="flex items-center justify-between mb-1 ">
          <p className="text-xs text-start opacity-30 mb-1.5 font-medium">
            Workspace
          </p>
          <button onClick={toggleCollapse}>
            {isCollapsed ? (
              <PlusOutlined className="text-[#B2B2B2] font-bold h-[2px] w-3"></PlusOutlined>
            ) : (
              <MinusOutlined className="text-[#B2B2B2] font-bold h-[2px] w-3"></MinusOutlined>
            )}
          </button>
        </div>
        <AnimatePresence initial={false}>
          {!isCollapsed && (
            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: "auto", opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              className="mb-2"
            >
              <AntInput
                ref={inputRef}
                classNames={{
                  input: "rounded-lg mx-0",
                }}
                placeholder="Untitled Workspace"
                value={workspaceName}
                onChange={(e) => setWorkspaceName(e.target.value)}
                onKeyDown={handleKeyDown}
                prefix={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                    data-slot="icon"
                    className="h-3.5 w-3.5"
                  >
                    <path d="M10.362 1.093a.75.75 0 0 0-.724 0L2.523 5.018 10 9.143l7.477-4.125-7.115-3.925ZM18 6.443l-7.25 4v8.25l6.862-3.786A.75.75 0 0 0 18 14.25V6.443ZM9.25 18.693v-8.25l-7.25-4v7.807a.75.75 0 0 0 .388.657l6.862 3.786Z"></path>
                  </svg>
                }
                suffix={
                  <span className="text-[#B2B2B2] items-center"> ↵ </span>
                }
                className="border-1 border-dashed border-[#B2B2B2]"
              />
            </motion.div>
          )}
        </AnimatePresence>
      </ul>
      <WorkspaceList
        workspaceData={workspaceData}
        isFetchingWorkspaces={isFetchingWorkspaces}
        selectedWorkSpace={selectedWorkSpace}
        handleworkspaceId={handleworkspaceId}
        setIsModalOpen={setIsModalOpen}
        setWorkspaceNameUpdate={setWorkspaceNameUpdate}
        setIsDeleteModalOpen={setIsDeleteModalOpen}
      />
      <CommonDialog
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Rename Workspace"
        icon={<EditOutlined />}
        primaryButtonText="Rename workspace"
        onPrimaryAction={updateWorkspace}
      >
        <Input
          placeholder="Workspace Name"
          className="focus:border-[#E4E4E7] border-[#E4E4E7] focus-visible:ring-offset-0 focus-visible:outline-none focus-visible:ring-0"
          value={workspaceNameUpdate}
          defaultValue={workspaceNameUpdate}
          onChange={(e) => setWorkspaceNameUpdate(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              updateWorkspace();
            }
          }}
        />
      </CommonDialog>

      <CommonDialog
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        title="Delete Workspace"
        icon={<DeleteOutlined />}
        primaryButtonText="Delete workspace"
        onPrimaryAction={() => deleteWorkspace(selectedWorkSpace)}
        primaryButtonClassName="bg-[#F44336] hover:bg-[#F44336] py-2.5 h-9 text-xs capitalize font-medium"
      >
        <div className="pb-2 text-sm text-[##191B33]">
          Are you sure you want to delete this form? This action cannot be
          undone.
        </div>
        <Separator className="absolute bottom-[3.5rem]  left-0" />
      </CommonDialog>
    </>
  );
};

export default Workspaces;

const WorkspaceList = ({
  workspaceData,
  isFetchingWorkspaces,
  selectedWorkSpace,
  handleworkspaceId,
  setIsModalOpen,
  setWorkspaceNameUpdate,
  setIsDeleteModalOpen,
}) => {
  return (
    <>
      {!isFetchingWorkspaces ? (
        workspaceData?.data?.workspaces?.length >= 1 &&
        !isFetchingWorkspaces ? (
          workspaceData?.data?.workspaces?.map((space) => (
            <button
              className={`group py-0.5 my-1 flex text-zinc-950  group  items-center rounded-lg px-2.5 text-[0.8rem] justify-between  ${
                selectedWorkSpace === space._id
                  ? "bg-zinc-100  font-bold"
                  : "bg-zinc-50"
              } `}
              key={space?._id}
              onClick={() => {
                handleworkspaceId(space?._id);
              }}
            >
              <div
                className={`text-zinc-950  group flex items-center justify-between rounded-lg px-2.5 py-2 text-[0.8rem]
      ${
        selectedWorkSpace === space?._id
          ? "bg-zinc-100  font-bold"
          : "bg-zinc-50"
      } `}
              >
                <div className="flex items-center space-x-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                    data-slot="icon"
                    className="h-3.5 w-3.5"
                  >
                    <path d="M10.362 1.093a.75.75 0 0 0-.724 0L2.523 5.018 10 9.143l7.477-4.125-7.115-3.925ZM18 6.443l-7.25 4v8.25l6.862-3.786A.75.75 0 0 0 18 14.25V6.443ZM9.25 18.693v-8.25l-7.25-4v7.807a.75.75 0 0 0 .388.657l6.862 3.786Z"></path>
                  </svg>
                  <span>{space?.name}</span>
                </div>
              </div>
              <Menubar className="p-0 bg-transparent border-0 shadow-none">
                <MenubarMenu>
                  <MenubarTrigger className="p-0 rounded-lg h-7 w-7 hover:bg-accent/20">
                    <div className="relative flex items-center justify-center hover:cursor-pointer">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="w-4 h-4"
                      >
                        <path d="M3 10a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0ZM8.5 10a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0ZM15.5 8.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Z" />
                      </svg>
                    </div>
                  </MenubarTrigger>
                  <MenubarContent className=" min-w-[8rem]">
                    <MenubarItem
                      className="flex items-center gap-2 text-xs font-medium cursor-pointer"
                      onClick={() => {
                        setIsModalOpen(true);
                        setWorkspaceNameUpdate(space?.name);
                      }}
                    >
                      <EditOutlined className="w-4 h-4" />
                      Rename
                    </MenubarItem>
                    <MenubarItem
                      className="flex items-center gap-2 text-xs font-medium text-red-500 cursor-pointer focus:text-red-500"
                      onClick={() => {
                        setIsDeleteModalOpen(true);
                      }}
                    >
                      <DeleteOutlined className="w-4 h-4 " />
                      Delete
                    </MenubarItem>
                  </MenubarContent>
                </MenubarMenu>
              </Menubar>
            </button>
          ))
        ) : (
          <div className="text-sm font-medium ">No workspaces to show</div>
        )
      ) : (
        <>
          <div className="py-0.5 my-1 flex group shadow  items-center rounded-lg px-2.5 animate-pulse-fast h-[35px] bg-gray-200 opacity-50"></div>
          <div className="py-0.5 my-1 flex group shadow  items-center rounded-lg px-2.5 animate-pulse-fast h-[35px] bg-gray-200 opacity-50"></div>
          <div className="py-0.5 my-1 flex group shadow  items-center rounded-lg px-2.5 animate-pulse-fast h-[35px] bg-gray-200 opacity-50"></div>
        </>
      )}
    </>
  );
};

const CommonDialog = ({
  isOpen,
  onClose,
  title,
  icon,
  children,
  primaryButtonText,
  onPrimaryAction,
  primaryButtonClassName = "py-2.5 h-9 text-xs capitalize font-medium",
}) => {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:w-[380px] p-3 top-[30%]">
        <DialogHeader>
          <DialogTitle className="gap-1 flex items-center text-sm font-medium text-[#191B33]">
            {icon}
            {title}
          </DialogTitle>
        </DialogHeader>
        <Separator className="absolute top-11" />
        <DialogDescription className="mt-4">{children}</DialogDescription>
        <DialogFooter>
          <Button
            onClick={onClose}
            variant="ghost"
            className="font-medium text-xs text-gray-800 border h-9 w-[80px]"
          >
            Cancel
          </Button>
          <Button onClick={onPrimaryAction} className={primaryButtonClassName}>
            {primaryButtonText}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
