"use strict";

import React from "react";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { useFormEditorContext } from "../../Contexts/FormEditorContext";
import WelcomeScreenSettings from "../MyEditor/WelcomeScreenSettings.jsx";
import { FormHeaderFormat } from "../../Components/Form/FormHeader";
import logoWithText from "../../assets/images/logoWithText.png";
import { Button } from "../ui/button.tsx";
import { FIELD_TYPE_SETTINGS } from "../../Constants/questionTypes.js";
import { FieldSettingEditor } from "../FormEditor/Fields/FieldSetting";

import QuestionScreen from "../FormEditor/Questions/QuestionScreen.tsx";
import WelcomeScreen from "../FormEditor/WelcomeScreen.tsx";
import EndScreen from "../FormEditor/EndScreen.tsx";
import FormButton from "../FormEditor/FormButton.tsx";

const questionAnimation = {
  initial: {
    opacity: 0,
    y: 400,
    x: 0, // Ensure horizontal position stays centered
    scale: 0.95, // Slight scale down for a smoother entry
  },
  animate: {
    opacity: 1,
    y: 0,
    x: 0,
    scale: 1,
  },
  exit: {
    opacity: 0,
    y: 40,
    x: 0,
    scale: 0.95,
  },
  transition: {
    type: "tween", // Use tween for more precise control
    duration: 0.5,
    ease: "easeOut",
  },
};

const ContentMainSection = () => {
  const { selectedField, fields, theme, device, formSettings } =
    useFormEditorContext();
  const index = fields?.findIndex((field) => field?.id === selectedField?.id);
  const showProgressBar = formSettings?.show_progress_bar;
  const [isAnimating, setIsAnimating] = React.useState(false);

  React.useEffect(() => {
    setIsAnimating(true);
  }, [device]);

  React.useEffect(() => {
    setIsAnimating(false);
  }, []);

  return (
    <div
      className="relative w-full h-full overflow-hidden"
      style={
        device === "mobile"
          ? {
              backgroundImage: `url('/dot-pattern.png')`,
              backgroundSize: "cover",
            }
          : {}
      }
    >
      <div className="absolute top-0 right-0 z-20 w-full justify-items-end">
        {selectedField === "welcome-screen" ? (
          <WelcomeScreenSettings />
        ) : (
          <QuestionSettings />
        )}
      </div>
      <div
        className={clsx(
          "absolute top-1 left-0 right-0 z-10 w-full px-[3.5px]",
          device === "mobile" ? "hidden" : ""
        )}
      >
        {selectedField !== "welcome-screen" &&
          selectedField !== "end-screen" &&
          selectedField && (
            <FormHeaderFormat
              showPercentage={selectedField !== "welcome-screen"}
              percentage={((index + 1) / fields.length) * 100}
              strokeColor={theme?.button_color || "rgb(208, 252, 3)"}
              showProgressBar={showProgressBar}
            />
          )}
      </div>

      <div
        className={clsx(
          "w-full h-full flex items-center justify-center",
          device === "mobile" ? "absolute inset-0" : "relative"
        )}
      >
        <motion.div
          className={clsx(
            "flex flex-col items-center justify-center overflow-hidden",
            device === "mobile"
              ? "rounded-[12px] border border-gray-100 drop-shadow-md"
              : ""
          )}
          initial={false}
          layout
          style={{
            backgroundColor: theme?.background_color,
            width: device === "mobile" ? "362px" : "100%",
            height: device === "mobile" ? "672px" : "100%",
            transformOrigin: "center center",
          }}
          transition={{
            layout: {
              type: "tween",
              stiffness: 100,
              damping: 20,
            },
          }}
          onLayoutAnimationComplete={() => setIsAnimating(false)}
          onLayoutAnimationStart={() => setIsAnimating(true)}
        >
          {/* Content remains the same */}
          <div
            className={clsx(
              "absolute top-1 left-0 right-0 z-10 w-full px-[3.5px]",
              isAnimating ? " opacity-0" : ""
            )}
          >
            {selectedField !== "welcome-screen" &&
              selectedField !== "end-screen" &&
              selectedField && (
                <FormHeaderFormat
                  showPercentage={selectedField !== "welcome-screen"}
                  percentage={((index + 1) / fields.length) * 100}
                  strokeColor={theme?.button_color || "rgb(208, 252, 3)"}
                  showProgressBar={showProgressBar}
                />
              )}
          </div>

          <div
            className={clsx(
              "absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center flex-grow w-full overflow-hidden",
              isAnimating ? " opacity-0" : ""
            )}
          >
            <AnimatePresence mode="wait">
              <div className="relative flex items-center justify-center w-full h-full">
                {selectedField === "welcome-screen" ? (
                  <motion.div
                    key="welcome-screen"
                    className="absolute flex items-center justify-center w-full h-full"
                    {...questionAnimation}
                  >
                    <WelcomeScreen />
                  </motion.div>
                ) : selectedField === "end-screen" ? (
                  <motion.div
                    key="end-screen"
                    className="absolute w-full flex justify-center items-center bottom-[45%]"
                    {...questionAnimation}
                  >
                    <EndScreen />
                  </motion.div>
                ) : (
                  <motion.div
                    key={selectedField?.id}
                    className="absolute flex items-center justify-center w-full h-full"
                    {...questionAnimation}
                  >
                    <QuestionScreen />
                  </motion.div>
                )}
              </div>
            </AnimatePresence>
          </div>

          <div
            className={clsx(
              "absolute left-0 right-0 z-30 bottom-1",
              isAnimating ? " opacity-0" : ""
            )}
          >
            {selectedField !== "end-screen" && selectedField && (
              <EditorFooter index={index} />
            )}
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default ContentMainSection;

const EditorFooter = ({ index }) => {
  const { fields, setSelectedField, theme, device, selectedField } =
    useFormEditorContext();
  const isLastField = index === fields?.length - 1;

  const nextField = fields[index + 1];
  const previousField = fields[index - 1];

  const handleFieldChange = (field) => {
    setSelectedField(field);
  };

  const nextButtonPress = () => {
    handleFieldChange(isLastField ? "end-screen" : nextField);
  };

  const previous = () => {
    handleFieldChange(index === 0 ? "welcome-screen" : previousField);
  };

  if (device === "mobile" && selectedField === "welcome-screen") {
    return (
      <div className=" h-[72px] absolute flex items-center justify-center w-full left-0 right-0 -bottom-2.5 bg-[#0000000c]">
        <FormButton>Start</FormButton>
      </div>
    );
  }

  return (
    <div className="relative w-full py-4 ">
      <div className="absolute bottom-2 left-[17px]">
        <img src={logoWithText} className="h-6 w-[108.75px]" alt="Logo" />
      </div>
      <div className="absolute flex gap-1 bottom-2 right-4 ">
        <Button
          icon="left"
          variant="ghost"
          className="p-0 w-9 h-9"
          onClick={previous}
          style={{
            backgroundColor: theme?.button_color,
          }}
        >
          <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 12.3496L6 8.34961L10 4.34961"
              stroke={theme?.button_text_color}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Button>
        <Button
          icon="right"
          variant="ghost"
          className="p-0 w-9 h-9"
          onClick={nextButtonPress}
          style={{
            backgroundColor: theme?.button_color,
          }}
        >
          <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 12.3496L10 8.34961L6 4.34961"
              stroke={theme?.button_text_color}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Button>
      </div>
    </div>
  );
};

const QuestionSettings = () => {
  const { selectedField, setEditorField, editorField } = useFormEditorContext();
  const fieldTypeSettings = FIELD_TYPE_SETTINGS[selectedField?.type];

  const renderSettings = (settings, parentKey = "") => {
    if (!settings) return null;

    return Object.keys(settings).map((key) => {
      const value = settings[key];
      const fullKey = parentKey ? `${parentKey}.${key}` : key;

      if (typeof value === "object" && !Array.isArray(value)) {
        return <div key={fullKey}>{renderSettings(value, fullKey)}</div>;
      }

      return (
        <FieldSettingEditor
          key={fullKey}
          type={value}
          label={fullKey}
          setEditorField={setEditorField}
          editorField={editorField}
        />
      );
    });
  };

  return (
    <AnimatePresence>
      {selectedField && (
        <motion.div
          key="question-settings"
          className="absolute flex gap-1 right-4 top-7"
          //{...questionAnimation}
        >
          {renderSettings(fieldTypeSettings)}
        </motion.div>
      )}
    </AnimatePresence>
  );
};
