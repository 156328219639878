import React from "react";
import { Flex } from "antd";
import {
  FacebookFilled,
  LinkedinFilled,
  TwitterSquareFilled,
} from "@ant-design/icons";

export const Socials = () => {
  const fullUrl = `${window.location.origin}${location.pathname}${location.search}`;
  /* const fullUrl = `https://app.buildform.ai/forms/OWMnbohqbVgi`; */

  const style = {
    fontSize: "40px",
  };

  const links = {
    facebookLink: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      fullUrl
    )}`,
    twitterLink: `https://twitter.com/intent/tweet?url=${encodeURIComponent(
      fullUrl
    )}&text=Check%20out%20this%20form!`,
    linkedinLink: `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
      fullUrl
    )}&title=Check%20this%20form`,
  };

  const onClick = (site) => {
    window.open(site, "_blank");
  };

  return (
    <Flex>
      <FacebookFilled
        style={{ ...style, color: "#1877f2" }}
        onClick={() => onClick(links.facebookLink)}
      />
      <TwitterSquareFilled
        style={{ ...style, color: "#00aced" }}
        onClick={() => onClick(links.twitterLink)}
      />
      <LinkedinFilled
        style={{ ...style, color: "#0077b5" }}
        onClick={() => onClick(links.linkedinLink)}
      />
    </Flex>
  );
};
