import React, { useMemo, useState } from "react";

import { useParams, useSearchParams } from "react-router-dom";

import { keepPreviousData, useInfiniteQuery } from "@tanstack/react-query";

// Components
import { DataTable } from "../Components/ResponsesTable/DataTable.tsx";
import { Checkbox } from "../Components/ui/checkbox.tsx";
import Header from "../Components/ResponsesTable/Header.tsx";
import Cell from "../Components/ResponsesTable/Cell.tsx";
import { Button } from "../Components/ui/button.tsx";

import { useApi } from "../Api/useApi.ts";
import { DEFAULT_PAGINATION } from "../Api/usePagination.ts";

import { useFormSubmissionsContext } from "../Contexts/FormSubmissionsContext.tsx";

const CheckboxHeader = {
  id: "select",
  header: ({ table }) => (
    <Checkbox
      checked={
        table.getIsAllPageRowsSelected() ||
        (table.getIsSomePageRowsSelected() && "indeterminate")
      }
      onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
      aria-label="Select all"
    />
  ),
  cell: ({ row }) => (
    <Checkbox
      checked={row.getIsSelected()}
      onCheckedChange={(value) => row.toggleSelected(!!value)}
      aria-label="Select row"
    />
  ),
  width: 10,
  enableSorting: false,
  enableHiding: false,
};

function FormResponsesView() {
  const api = useApi();
  const { urlId } = useParams();
  const [searchParams] = useSearchParams();

  const query = searchParams.get("query");
  const from = searchParams.get("from");
  const to = searchParams.get("to");
  const type = searchParams.get("type");

  const { fields, loading } = useFormSubmissionsContext();

  const [pagination, setPagination] = useState({
    page: DEFAULT_PAGINATION.page,
    size: DEFAULT_PAGINATION.size,
  });

  // const {
  //   isFetched: isFormResponsesFetched,
  //   data: formResponses,
  //   isSuccess,
  //   isError,
  // } = useQuery({
  //   queryKey: [
  //     "formResponses",
  //     query,
  //     from,
  //     to,
  //     type,
  //     pagination.page,
  //     pagination.size,
  //   ],
  //   queryFn: () =>
  //     api.forms.fetchFormResponses(urlId, pagination, {
  //       query,
  //       date: { from, to },
  //       responseType: type,
  //     }),
  //   staleTime: 1000,
  //   placeholderData: keepPreviousData,
  // });

  const {
    data: formResponses,
    isFetched: isFormResponsesFetched,
    isFetchingNextPage,
    fetchNextPage,
    error,
  } = useInfiniteQuery({
    queryKey: [
      "formResponses",
      query,
      from,
      to,
      type,
      pagination.page,
      pagination.size,
    ],
    queryFn: ({ pageParam = 1 }) =>
      api.forms.fetchFormResponses(
        urlId,
        { page: pageParam, size: 50 },
        {
          query,
          date: { from, to },
          responseType: type,
        }
      ),
    initialPageParam: 0,
    placeholderData: keepPreviousData,
    getNextPageParam: (lastPage, allPages, lastPageParam, allPageParams) =>
      // lastPage.nextCursor,
      // setPagination((prev) => prev + 1)
      lastPage?.length > 0 ? allPages.length + 1 : undefined,
    // getPreviousPageParam: (
    //   firstPage,
    //   allPages,
    //   firstPageParam,
    //   allPageParams
    // ) => firstPage.prevCursor,
  });

  // TODO: Use React observer library to implement infinite scrolling
  // useEffect(() => {
  //   const observer = new IntersectionObserver(
  //     (entries) => {
  //       if (entries[0].isIntersecting) {
  //         loadMoreReplies();
  //       }
  //     },
  //     { threshold: 1.0 }
  //   );

  //   if (loadMoreTrigger.current) {
  //     observer.observe(loadMoreTrigger.current);
  //   }

  //   return () => {
  //     if (loadMoreTrigger.current) {
  //       observer.unobserve(loadMoreTrigger.current);
  //     }
  //   };
  // }, [currentPage, isFetching, displayedReplies]);

  const getColumns = () => {
    const filteredFields = fields
      // ?.filter((field) => field.type !== "ai_statement")
      .filter((field) => field.checked);

    const columns = filteredFields?.map((field, i) => {
      return {
        id: i + 1,
        accessorKey: field.title,
        header: ({ column }) => {
          return (
            <Header
              title={field.title}
              type={field.type}
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === "asc")
              }
            />
          );
        },
        cell: ({ row }) => {
          return (
            <Cell
              data={row.original?.[field.title]}
              type={field.type}
              onClick={() => {}}
            />
          );
        },
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        },
      };
    });
    columns.unshift(CheckboxHeader);
    return columns;
  };

  const columns = useMemo(() => getColumns(), [fields]);

  const getData = () => {
    if (
      error ||
      !isFormResponsesFetched ||
      !formResponses ||
      formResponses?.pages?.length === 0
    )
      return [];

    const out = [];
    // Loop through the responses
    for (const response of formResponses?.pages?.[0].data?.responses) {
      const qaArray = response.data;
      const entry = {
        id: response?.response_id,
        Date: response?.updated_at,
        State: response?.type,
      };
      // Loop through the fields of each response
      for (const field of fields) {
        const question = qaArray.find(
          (qa) => qa.question.question_id === field.id
        );
        const answers = question?.answer?.map((a) => a?.answer_text) ?? [];
        if (field.title === "Date") entry["Date"] = response?.updated_at;
        else if (field.title === "State") entry["State"] = response?.type;
        else entry[field.title] = answers;
      }
      out.push(entry);
    }
    return out;
  };

  const data = getData();

  return (
    <div className="relative h-[calc(100vh-6rem)] overflow-auto">
      {/* <Button
        disabled={isFetchingNextPage}
        onClick={() => {
          console.log("clicked");
          fetchNextPage();
        }}
      >
        Load more
      </Button> */}
      <DataTable
        data={data ?? []}
        columns={columns}
        isLoaded={isFormResponsesFetched && !loading}
        total={formResponses?.data?.total ?? 0}
        // onPaginationChange={(pagination) =>
        //   setPagination({
        //     page: pagination.pageIndex + 1,
        //     size: pagination.pageSize,
        //   })
        // }
        onSortingChange={(sorting) => {}}
        onScrollEnd={() => {
          fetchNextPage();
        }}
      />
    </div>
  );
}

export default FormResponsesView;
