import React, { useEffect } from "react";
import { SignUp } from "@clerk/clerk-react";
import { useDispatch } from "react-redux";
import { resetStore } from "../../Redux/slices/formSlice.tsx";
import { persistor } from "../../Redux/store.jsx";
import SigninContent from "../../Components/Auth/SigninContent.tsx";
import LoginContent from "../../Components/Auth/LoginContent.tsx";

export const LoginView = ({ isLogin = true }) => {
  const dispatch = useDispatch();

  /* Hook to clear session and Redux store when component mounts */
  useEffect(() => {
    sessionStorage.clear(); // Clears all session storage data
    dispatch(resetStore()); // Dispatch action to reset Redux store
    persistor.purge(); // Additionally purge the persistence layer
  }, [dispatch]);

  return (
    <div className="flex items-center justify-center w-full h-screen ">
      {isLogin ? (
        <>
          {/* <SignIn signUpUrl="/register" /> */}
          <LoginContent />
        </>
      ) : (
        <>
          {/*   <LoginContent />  */}
          <SigninContent />
        </>
      )}
    </div>
  );
};
