import React, { useEffect, useState } from "react";
import CommonLayout from "./CommonLayout.tsx";
import { ROUTES } from "../../Constants/routes.js";
import { useSignUp, useClerk } from "@clerk/clerk-react";
import { ArrowRight } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { Input } from "../ui/input.tsx";
import { InputOTP, InputOTPSlot } from "../ui/input-otp.tsx";
import OAuthSignIn from "./OAuthSignIn.tsx";
import PasswordInput from "../ui/password-input.tsx";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import {
  Form,
  FormField,
  FormItem,
  FormControl,
  FormMessage,
} from "../ui/form.tsx";
import { Button } from "../ui/button.tsx";

const signUpSchema = z.object({
  firstName: z.string().min(2, "First name cannot be empty"),
  lastName: z.string().optional(),
  email: z
    .string()
    .min(1, "Email field cannot be empty")
    .email("Please enter a valid email address"),
  password: z.string().min(8, "Password must be at least 8 characters"),
});

const SigninContent = () => {
  const navigate = useNavigate();
  const { setActive } = useClerk();
  const { isLoaded, signUp } = useSignUp();
  const form = useForm({
    resolver: zodResolver(signUpSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
    },
  });

  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [pendingVerification, setPendingVerification] =
    useState<boolean>(false);
  const [code, setCode] = useState<string>("");
  const [canResend, setCanResend] = useState<boolean>(false); // Disable resend button
  const [countdown, setCountdown] = useState<number>(60);

  const onSubmit = async (data) => {
    if (!isLoaded) return;

    try {
      setLoading(true);
      setError("");

      // Start the sign-up process
      const result = await signUp.create({
        firstName: data.firstName,
        lastName: data.lastName || "", // Make it optional
        emailAddress: data.email,
        password: data.password,
      });

      // Handle email verification if required
      if (result.status === "missing_requirements") {
        const emailVerification = result.verifications?.emailAddress;

        if (emailVerification) {
          await signUp.prepareEmailAddressVerification();
          setPendingVerification(true);
          setCountdown(60);
        }
      }
    } catch (err) {
      setError(err.message || "An error occurred during sign up");
    } finally {
      setLoading(false);
    }
  };

  const handleVerification = async () => {
    if (!isLoaded) return;

    try {
      setLoading(true);
      setError("");

      const result = await signUp.attemptEmailAddressVerification({
        code,
      });

      if (result.status === "complete") {
        // Set the newly created session as active
        if (result.createdSessionId) {
          await setActive({ session: result.createdSessionId });
        }

        // Show success message (optional)

        // Redirect to the dashboard or home page
        navigate(`${process.env.REACT_APP_CLERK_SIGN_UP_FORCE_REDIRECT_URL}`);
      }
    } catch (err) {
      console.error("Verification error:", err);
      setError(err.message || "Error during verification");
    } finally {
      setLoading(false);
    }
  };

  //if code has 6 digits, verify the email
  useEffect(() => {
    if (code.length === 6) {
      handleVerification();
    }
  }, [code]);

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);

      // Cleanup the timer on component unmount
      return () => clearTimeout(timer);
    } else {
      setCanResend(true); // Allow resend when countdown reaches zero
    }
  }, [countdown]);

  // Get the email link flow functions
  const handleResend = (e) => {
    e.preventDefault();
    const data = form?.getValues();
    setCanResend(false); // Disable resend initially
    setCountdown(60); // Restart countdown
    onSubmit(data);
  };

  if (pendingVerification) {
    return (
      <CommonLayout>
        <>
          <div className="flex flex-col w-full gap-1.5 ">
            <h1 className=" font-semibold text-[1.5rem]">
              Let's Verify Your Email
            </h1>
            <p className=" text-[#475467] text-[0.875rem]">
              Please enter the 6-digit code sent to{" "}
            </p>
            <div className="flex items-center justify-center gap-1.5 ">
              <p className=" text-[#191B33] text-[0.879rem]">
                {form?.getValues("email")}
              </p>
              <button
                className=" hover:cursor-pointer"
                onClick={() => {
                  setError("");
                  setPendingVerification(false);
                  navigate(ROUTES.SIGNUP);
                }}
              >
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.3335 3.16665H2.66683C2.31321 3.16665 1.97407 3.30713 1.72402 3.55718C1.47397 3.80723 1.3335 4.14637 1.3335 4.49999V13.8333C1.3335 14.1869 1.47397 14.5261 1.72402 14.7761C1.97407 15.0262 2.31321 15.1667 2.66683 15.1667H12.0002C12.3538 15.1667 12.6929 15.0262 12.943 14.7761C13.193 14.5261 13.3335 14.1869 13.3335 13.8333V9.16666M12.3335 2.16665C12.5987 1.90144 12.9584 1.75244 13.3335 1.75244C13.7086 1.75244 14.0683 1.90144 14.3335 2.16665C14.5987 2.43187 14.7477 2.79158 14.7477 3.16665C14.7477 3.54173 14.5987 3.90144 14.3335 4.16665L8.00016 10.5L5.3335 11.1667L6.00016 8.49999L12.3335 2.16665Z"
                    stroke="#09090B"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="flex flex-col justify-center w-full gap-4 mt-2 ">
            {/* horizontal line  */}
            <div className="flex w-full mx-auto mt-2 ">
              <form
                onSubmit={handleVerification}
                className="justify-center w-full"
              >
                <InputOTP
                  maxLength={6}
                  className="w-full "
                  value={code}
                  /* onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCode(e.target.value)} */
                  onChange={(value) => {
                    setCode(value);
                  }}
                >
                  <InputOTPSlot index={0} />
                  <InputOTPSlot index={1} />
                  <InputOTPSlot index={2} />
                  <InputOTPSlot index={3} />
                  <InputOTPSlot index={4} />
                  <InputOTPSlot index={5} />
                </InputOTP>
                {error && (
                  <div className="mt-2 gap-1.5 flex items-center w-full text-start text-sm text-[#F44336]">
                    <svg
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.49992 5.33325V7.99992M8.49992 10.6666H8.50659M15.1666 7.99992C15.1666 11.6818 12.1818 14.6666 8.49992 14.6666C4.81802 14.6666 1.83325 11.6818 1.83325 7.99992C1.83325 4.31802 4.81802 1.33325 8.49992 1.33325C12.1818 1.33325 15.1666 4.31802 15.1666 7.99992Z"
                        stroke="#F44336"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    {error}
                  </div>
                )}
              </form>
            </div>
            <p className="text-[#5E5F70] font-medium text-[0.879rem]">
              Didn’t receive a link?{" "}
              {canResend ? (
                <button
                  className="text-blue-500 cursor-pointer"
                  onClick={(e) => {
                    setError("");
                    setCode("");
                    handleResend(e);
                  }}
                >
                  Resend
                </button> // Add click functionality as needed
              ) : (
                `Resend (${countdown})`
              )}
            </p>

            <hr className="w-full border-[#D1D1D6]" />

            <button
              onClick={() => {
                setError("");
                setCode("");
                setPendingVerification(false);
                navigate(ROUTES.SIGNUP);
              }}
              className=" text-[#191B33] font-medium text-[0.879rem]"
            >
              Use another method
            </button>
          </div>
        </>
      </CommonLayout>
    );
  }

  return (
    <CommonLayout>
      <>
        <div className="flex flex-col w-full ">
          <h1 className=" font-semibold text-[1.5rem]">Create your account</h1>
          <p className=" text-[#475467] text-[0.879rem]">
            Welcome! Please fill in the details to get started.
          </p>
        </div>
        <div className="flex flex-col w-full gap-4 mt-[32px] ">
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="w-full space-y-2"
            >
              <div className="grid grid-cols-2 gap-2">
                <FormField
                  control={form.control}
                  name="firstName"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Input
                          placeholder="First name"
                          {...field}
                          className={
                            form.formState.errors.firstName
                              ? "border-[#F44336] focus-visible:ring-offset-0 focus-visible:outline-none focus-visible:ring-0"
                              : " focus:border-[#A1A1AA] border-[#E4E4E7] focus-visible:ring-offset-0 focus-visible:outline-none focus-visible:ring-0"
                          }
                        />
                      </FormControl>
                      {/*   <FormMessage className=" text-[#F44336]" /> */}
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="lastName"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Input
                          className="focus:border-[#A1A1AA] border-[#E4E4E7] focus-visible:ring-offset-0 focus-visible:outline-none focus-visible:ring-0"
                          placeholder="Last name (Optional)"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage className=" text-[#F44336]" />
                    </FormItem>
                  )}
                />
              </div>
              {form.formState.errors.firstName && (
                <FormMessage className=" text-[#F44336]">
                  {form.formState.errors.firstName.message}
                </FormMessage>
              )}

              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        placeholder="Email Address"
                        {...field}
                        className={
                          form.formState.errors.email
                            ? "border-[#F44336] focus-visible:ring-offset-0 focus-visible:outline-none focus-visible:ring-0"
                            : " focus:border-[#A1A1AA] border-[#E4E4E7] focus-visible:ring-offset-0 focus-visible:outline-none focus-visible:ring-0"
                        }
                      />
                    </FormControl>
                    <FormMessage className="  text-[#F44336]" />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <PasswordInput
                        {...field}
                        error={!!form.formState.errors.password}
                      />
                    </FormControl>
                    <FormMessage className=" text-[#F44336]" />
                  </FormItem>
                )}
              />

              {error &&
                Object.keys(form?.formState?.errors || {}).length === 0 && (
                  <FormMessage className="text-[#F44336]">{error}</FormMessage>
                )}

              <Button
                type="submit"
                disabled={loading}
                className="w-full h-10 bg-[#191B33] text-zinc-50 mt-2"
              >
                <span className="text-base font-medium">
                  {loading ? "Creating account..." : "Create account"}
                </span>
                <ArrowRight className="w-4 h-4 ml-1.5" />
              </Button>
            </form>
          </Form>
          <div className="flex items-center justify-center gap-x-1.5">
            <span className="text-sm font-medium ">
              Already have an account?
            </span>
            <a
              href={ROUTES.LOGIN}
              className="text-[#2563EB] text-sm font-medium  "
            >
              Sign in
            </a>
          </div>

          <div className="relative mt-2">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-300"></div>
            </div>
            <div className="relative flex justify-center text-[12px]">
              <span className="px-2 text-gray-500 uppercase bg-white">
                Or continue with
              </span>
            </div>
          </div>
        </div>
        <OAuthSignIn setError={setError} />

        <div className="col-span-1 "></div>
      </>
    </CommonLayout>
  );
};

export default SigninContent;
