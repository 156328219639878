import React, { useState } from "react";
import { Eye, EyeOff } from "lucide-react";
import { Input } from "./input.tsx";

const PasswordInput = ({ error, disabled, value, onChange }) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(true);
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);

  const handlePasswordChange = (e) => {
    const newValue = e.target.value;
    onChange(e);
    setIsValid(newValue.length >= 8);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="relative w-full">
      <Input
        className={`
          ${
            error
              ? "border-[#F44336] focus-visible:ring-offset-0 focus-visible:outline-none focus-visible:ring-0"
              : " focus:border-[#A1A1AA] border-[#E4E4E7] focus-visible:ring-offset-0 focus-visible:outline-none focus-visible:ring-0"
          }
          `}
        type={showPassword ? "text" : "password"}
        placeholder="Enter your password"
        value={value}
        onChange={handlePasswordChange}
        disabled={disabled}
        onFocus={() => setShowSuggestions(true)}
      />

      <button
        type="button"
        onClick={togglePasswordVisibility}
        className="absolute text-[#191B33] right-3 top-3 hover:text-gray-600"
        disabled={disabled}
      >
        {showPassword ? (
          <Eye className="w-4 h-4 " aria-hidden="true" />
        ) : (
          <EyeOff className="w-4 h-4" aria-hidden="true" />
        )}
      </button>
      {showSuggestions && (
        <div
          className={`
             
            ${isValid || error ? "hidden" : "block pt-1"}
          `}
        >
          <p className="text-sm text-[#757685] text-start">
            Password must contain 8 or more characters.{" "}
          </p>
        </div>
      )}
    </div>
  );
};

export default PasswordInput;
